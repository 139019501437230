const baseRoutes = [{
  path: '/finance/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
/*    {
      path: 'finance',
      component: () => import('@/views/finances/FinanceView.vue'),
      name: 'finance',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },*/
    {
      path: 'rentability',
      component: () => import('@/components/finances/RentabilityPage.vue'),
      name: 'finance.rentability',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'income',
      component: () => import('@/components/finances/RevenuePage.vue'),
      name: 'finance.income',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'expenses',
      component: () => import('@/components/finances/ExpensesPage.vue'),
      name: 'finance.expenses',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'invoices',
      component: () => import('@/components/finances/InvoicesPage.vue'),
      name: 'finance.invoices',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'payments',
      component: () => import('@/components/finances/PaymentsPage.vue'),
      name: 'finance.payments',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default baseRoutes;
