import { useAppStore } from '@/stores/app';

const PropertyService = {
  async getProperties(force: boolean = false) {
    const appStore = useAppStore();

    await appStore.getProperties(force);
  },
};

export { PropertyService };
