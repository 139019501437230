import { AuthService } from '@/services/auth_service';

export function usePermissions() {
  const hasPermission = async (permissionToCheck: any) => {
    const userPermissions = await AuthService.getPermissions();
    return userPermissions[permissionToCheck] !== undefined;
  };

  const hasPermissions = async (permissionsToCheck: any) => {
    let response = true;

    // for (const permission of permissionsToCheck) {
    for (let i = 0; i < permissionsToCheck.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      response = response && await hasPermission(permissionsToCheck[i]);
    }

    return response;
  };

  // Return
  return {
    hasPermission,
    hasPermissions,
  };
}
