const loginRoutes = [{
  path: '/',
  component: () => import('../layouts/EmptyLayout.vue'),
  children: [
    // Redirect to Dashboard
    {
      path: '',
      redirect: 'dashboard',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    {
      path: 'login',
      name: 'login',
      component: () => import('../views/auth/Login.vue'),
      meta: {
        auth: false,
        permissions: [],
        layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'forgotPassword',
      name: 'forgotPassword',
      component: () => import('../views/auth/ForgotPassword.vue'),
      meta: {
        auth: false,
        permissions: [],
        layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'reset-password',
      name: 'resetPassword',
      component: () => import('../views/auth/ResetPassword.vue'),
      meta: {
        auth: false,
        permissions: [],
        layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'unimpersonate',
      name: 'unimpersonate',
      component: () => import('../views/auth/UnimpersonatePage.vue'),
      meta: {
        auth: true,
        permissions: [],
        layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'logout',
      name: 'logout',
      component: () => import('../views/auth/LogoutPage.vue'),
      meta: {
        auth: true,
        permissions: [],
        layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // {
    //   path: '2fa-validate',
    //   name: '2faValidate',
    //   component: () => import('../views/auth/TwoFAValidate.vue'),
    //   meta: {
    //     auth: true,
    //     permissions: [],
    //     layout: 'all',
    //     // title: 'Dashboard',
    //     // icon: 'home',
    //     // keepAlive: true
    //   },
    // },
  ],
}];

export default loginRoutes;
