const marketplaceRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Management
    {
      path: '/marketplace',
      component: () => import('../views/marketplace/MarketplaceIndex.vue'),
      name: 'marketplace.index',
      meta: {
        auth: true,
        permissions: ['marketplace_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

  ],
}];

export default marketplaceRoutes;
