const ownerRoutes = [{
  path: '/owner',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // #################################
    // ########## Dashboard ############
    // #################################
    {
      path: '/owner/dashboard',
      component: () => import('@/views/DashboardView.vue'),
      name: 'owner.dashboard',
      meta: {
        auth: true,
        permissions: ['owner_dashboard_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######## Accommodations #########
    // #################################
    // Accommodations List
    {
      path: '/owner/accommodations',
      component: () => import('@/views/accommodations/AccommodationsListView.vue'),
      name: 'owner.accommodation.index',
      meta: {
        auth: true,
        permissions: ['owner_accommodation_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Accommodations View
    {
      path: '/owner/accommodations/:accommodationId',
      component: () => import('@/views/accommodations/AccommodationsEditView.vue'),
      name: 'owner.accommodation.edit',
      meta: {
        auth: true,
        permissions: ['owner_accommodation_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######## Rates/Calendar #########
    // #################################
    // Rates Show
    {
      path: '/owner/rates/:accommodationId/',
      component: () => import('../views/rates/RatesEditView.vue'),
      name: 'owner.rates.edit',
      meta: {
        auth: true,
        permissions: ['owner_calendar_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Rates Multi Calendar
    {
      path: '/owner/rates/price-map',
      component: () => import('@/views/rates/PriceMapView.vue'),
      name: 'owner.rates.price_map',
      meta: {
        auth: true,
        permissions: ['owner_calendar_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######### Reservations ##########
    // #################################
    // Reservations List
    {
      path: '/owner/reservations',
      component: () => import('@/views/reservations/ListView.vue'),
      name: 'owner.reservation.index',
      meta: {
        auth: true,
        permissions: ['owner_reservation_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Reservations Edit
    {
      path: '/owner/reservations/:reservationId',
      component: () => import('@/views/reservations/EditView.vue'),
      name: 'owner.reservation.edit',
      meta: {
        auth: true,
        permissions: ['owner_reservation_create_edit'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Reservations New
    {
      path: '/owner/reservations/new',
      component: () => import('@/views/reservations/NewView.vue'),
      name: 'owner.reservation.new',
      meta: {
        auth: true,
        permissions: ['owner_reservation_create_edit'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // View Reservations MAP
    {
      path: '/owner/reservations/map',
      component: () => import('@/views/reservations/ReservationsMapView.vue'),
      name: 'owner.reservation.map',
      meta: {
        auth: true,
        permissions: ['owner_reservation_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Task Check-ins-outs
    {
      path: '/owner/tasks/check-ins-outs',
      component: () => import('@/views/tasks/CheckInOuts.vue'),
      name: 'owner.check_in_out',
      meta: {
        auth: true,
        permissions: ['owner_view_ci_co_page'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ########### Owners ##############
    // #################################
    {
      path: '/owner/owners/owner',
      component: () => import('../components/owners/OwnersOverview.vue'),
      name: 'owner.owners.owner',
      meta: {
        auth: true,
        permissions: ['owner_owners_view'],
        layout: 'owner',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/owner/owners/settings',
      component: () => import('../components/owners/OwnersList.vue'),
      name: 'owner.owners.settings',
      meta: {
        auth: true,
        permissions: ['owner_owners_view'],
        layout: 'owner',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ########## Invoices #############
    // #################################
    // Invoices
    {
      path: '/owner/invoices/issued',
      component: () => import('@/views/invoicing/IssuedView.vue'),
      name: 'owner.invoices.issued',
      meta: {
        auth: true,
        permissions: ['owner_invoicing_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // To issue Invoices
    {
      path: '/owner/invoices/to-issue',
      component: () => import('@/views/invoicing/ToIssueView.vue'),
      name: 'owner.invoices.to_issue',
      meta: {
        auth: true,
        permissions: ['owner_invoicing_create'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ########## Finances #############
    // #################################
    // Expenses
    {
      path: '/owner/finance/expenses',
      component: () => import('@/components/finances/ExpensesPage.vue'),
      name: 'owner.finances.expenses',
      meta: {
        auth: true,
        permissions: ['owner_finance_expenses_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Invoices
    {
      path: '/owner/finance/invoices',
      component: () => import('@/components/finances/InvoicesPage.vue'),
      name: 'owner.finances.invoices',
      meta: {
        auth: true,
        permissions: ['owner_finance_invoices_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######### Statistics ############
    // #################################
    // Indicators
    {
      path: '/owner/statistics/indicators',
      component: () => import('@/components/statistics/Indicators.vue'),
      name: 'owner.statistics.indicators',
      meta: {
        auth: true,
        permissions: ['owner_stats_indicators_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Reservations
    {
      path: '/owner/statistics/reservations',
      component: () => import('@/components/statistics/Reservations.vue'),
      name: 'owner.statistics.reservations',
      meta: {
        auth: true,
        permissions: ['owner_stats_reservations_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ###### Reminders & Notes ########
    // #################################
    // Reminders
    {
      path: '/owner/reminders',
      component: () => import('../views/remindersView.vue'),
      name: 'owner.reminders',
      meta: {
        auth: true,
        permissions: [],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Notes
    {
      path: '/owner/notes',
      component: () => import('../views/notesView.vue'),
      name: 'owner.notes',
      meta: {
        auth: true,
        permissions: [],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######### Marketplace ###########
    // #################################
    {
      path: '/owner/marketplace',
      component: () => import('../views/marketplace/MarketplaceIndex.vue'),
      name: 'owner.marketplace',
      meta: {
        auth: true,
        permissions: ['owner_marketplace_view'],
        layout: 'owner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ########## LiveChat #############
    // #################################
    {
      path: '/owner/live-chat',
      component: () => import('@/components/schedulers/Owners/OwnerLiveChat.vue'),
      name: 'owner.live_chat',
      meta: {
        auth: true,
        permissions: ['owner_communications_livechat_view'],
        title: 'Live Chat',
        layout: 'owner',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ########## Timeline #############
    // #################################
    {
      path: '/owner/timeline',
      component: () => import('../views/TimelineView.vue'),
      name: 'owner.timeline',
      meta: {
        auth: true,
        permissions: ['owner_timeline_view'],
        title: 'Timeline',
        layout: 'owner',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default ownerRoutes;
