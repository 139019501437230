const ownerStatisticsRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    {
      path: '/owners/owner',
      component: () => import('../components/owners/OwnersOverview.vue'),
      name: 'owners.owner',
      meta: {
        auth: true,
        permissions: [/*'owners_view'*/],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/owners/manager',
      component: () => import('../components/owners/ManagersOverview.vue'),
      name: 'owners.manager',
      meta: {
        auth: true,
        permissions: [/*'owners_view'*/],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/owners/settings',
      component: () => import('../views/owners/OwnerView.vue'),
      name: 'owners.settings',
      meta: {
        auth: true,
        permissions: [/*'owners_view'*/],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    }
  ]
}];

export default ownerStatisticsRoutes;
