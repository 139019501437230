const digitalConciergeRoutes = [
  // Check-in-online
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '/digital-concierge',
        component: () => import('../views/digitalConcierge/CheckInOnline.vue'),
        name: 'digitalConcierge',
        meta: {
          auth: true,
          permissions: ['ci_online_view'],
          // title: 'Dashboard',
          // icon: 'home',
          // keepAlive: true
        },
      }
    ]
  }
];

export default digitalConciergeRoutes;
