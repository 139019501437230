const loginRoutes = [{
  path: '/',
  component: () => import('../layouts/EmptyLayout.vue'),
  children: [
    {
      path: 'setup/:token',
      name: 'setup.form',
      component: () => import('../views/setupForm/SetupForm.vue'),
      meta: {
        auth: false,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default loginRoutes;
