const cleanersRoutes = [{
  path: '/housekeeping',
  component: () => import('@/layouts/MainLayout.vue'),
  children: [
    // List
    {
      path: '/housekeeping/tasks/housekeeping',
      component: () => import('../views/tasks/CleaningsView.vue'),
      name: 'housekeeping.housekeeping_list',
      meta: {
        auth: true,
        permissions: ['housekeeping_housekeeping_view'],
        title: 'List',
        layout: 'cleaner',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Maintenance
    {
      path: '/housekeeping/maintenance/list',
      component: () => import('@/views/tasks/Maintenance.vue'),
      name: 'housekeeping.maintenance',
      meta: {
        auth: true,
        permissions: ['housekeeping_maintenance_view'],
        title: 'Maintenance',
        layout: 'cleaner',
        // icon: 'home',
        // keepAlive: true
      },
    },
    //  Live-Chat
    {
      path: '/housekeeping/live-chat',
      component: () => import('@/components/schedulers/Cleaners/CleanerLiveChat.vue'),
      name: 'housekeeping.live_chat',
      meta: {
        auth: true,
        permissions: ['housekeeping_communications_livechat_view'],
        title: 'Live Chat',
        layout: 'cleaner',
        // icon: 'home',
        // keepAlive: true
      },
    },
    //  Statistics
    {
      path: '/housekeeping/statistics',
      component: () => import('@/components/statistics/tasks/Cleanings.vue'),
      name: 'housekeeping.statistics',
      meta: {
        auth: true,
        permissions: ['housekeeping_stats_tasks_housekeeping_view'],
        title: 'Statistics',
        layout: 'cleaner',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Reminder && Notes
    {
      path: '/housekeeping/reminders',
      component: () => import('../views/remindersView.vue'),
      name: 'housekeeping.reminders',
      meta: {
        auth: true,
        permissions: [],
        layout: 'cleaner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/housekeeping/notes',
      component: () => import('../views/notesView.vue'),
      name: 'housekeeping.notes',
      meta: {
        auth: true,
        permissions: [],
        layout: 'cleaner',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Networking
    {
      path: '/housekeeping/networking',
      component: () => import('@/views/networking/NetworkingIndex.vue'),
      name: 'housekeeping.networking',
      meta: {
        auth: true,
        permissions: ['housekeeping_networking_view'],
        title: 'Networking',
        layout: 'cleaner',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Marketplace
    {
      path: '/housekeeping/marketplace',
      component: () => import('@/views/marketplace/MarketplaceIndex.vue'),
      name: 'housekeeping.marketplace',
      meta: {
        auth: true,
        permissions: ['housekeeping_marketplace_view'],
        title: 'Networking',
        layout: 'cleaner',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Timeline
    {
      path: '/housekeeping/timeline',
      component: () => import('@/views/TimelineView.vue'),
      name: 'housekeeping.timeline',
      meta: {
        auth: true,
        permissions: ['housekeeping_timeline_view'],
        title: 'Timeline',
        layout: 'cleaner',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default cleanersRoutes;
