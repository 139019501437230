import { defineStore } from 'pinia';
import ApiService from '@/services/api_service';

export const useAppStore = defineStore('app', {
  state: () => ({
    loading: false,
    properties: [] as any,
    propertiesMap: {} as any,
    propertyID: 0,
    firstAccommodationID: 0,
    countries: null,
    menuIsCollapsed: false,
    liveChatNotification: false,
    guide: {},
    notifications: {
      timeline: 0,
      assistant: 0,
      communications: {
        live_chat_count: 0,
        whatsapp_count: 0
      },
      platform: [],
    },
    pageName: null,
    hasInvoicingSubscribed: false
  }),
  getters: {
    isLoading: state => state.loading,
    // properties: (state) => state.properties,
    // propertyID: (state) => state.propertyID,
  },
  actions: {
    startLoading() {
      this.loading = true;
    },

    stopLoading() {
      this.loading = false;
    },

    async getProperties(force: boolean = false) {
      if (this.properties.length === 0 || force) {
        const response = await ApiService.get('/api/fe/properties?withAccRP=true');
        this.properties = response.data.data;

        if(this.properties.length === 1){
          this.propertyID = this?.properties[0]?.id;
        }
        else {
          this.propertyID = 0;
        }

        this.firstAccommodationID = this?.properties[0]?.accommodations[0]?.id;

        this.properties.forEach((property: any, index: number) => {
          this.propertiesMap[property.id] = index;
          if (property.subscribed_modules) {
            for (const [module, value] of Object.entries(property.subscribed_modules)) {
              if (module === 'invoicing' && value === 1) {
                this.hasInvoicingSubscribed = true;
              }
            }
          }
        });
      }

      return this.properties;
    },

    async getPropertyID() {
      if(this.properties.length === 0) {
        await this.getProperties();
      }

      return this.propertyID;
    },

    getAccommodations(propertyID: number | undefined = undefined) {
      let searchID = this.propertyID;
      if (propertyID) {
        searchID = propertyID;
      }
      if (searchID > 0) {
        const val: any = this.properties.find((property: any) => property.id === searchID);
        if (val) {
          return val.accommodations;
        }
      }

      return [];
    },

    getTypologiesRooms(propertyID: number | undefined = undefined) {
      const accommodations = this.getAccommodations(propertyID);
      const response = [];

      accommodations.forEach((accommodation) => {
        accommodation.typologies.forEach((typology) => {
          response.push({
            id: `${typology.accommodation_id}|${typology.order}`,
            name: typology.name
          });
        });
      });

      return response;
    },

    getTypologiesRoomsWithAccommodationName(propertyID: number | undefined = undefined) {
      const accommodations = this.getAccommodations(propertyID);
      const response = [];

      accommodations.forEach((accommodation) => {
        accommodation.typologies.forEach((typology) => {
          response.push({
            id: `${typology.accommodation_id}|${typology.order}`,
            name: accommodation.name === typology.name ? accommodation.name : `${accommodation.name} | ${typology.name}`,
            has_owner: accommodation.has_owner
          });
        });
      });

      return response;
    },

    getRatePlans(propertyID: number, accommodationID: number) {
      if(! this.properties || this.properties.length === 0) {
        return [];
      }
      if(propertyID === 0){
        propertyID = this.properties[0].id;
        this.setProperty(propertyID);
      }

      const index = this.propertiesMap[propertyID];

      // eslint-disable-next-line max-len
      const val: any = this.properties[index]?.accommodations?.find((accommodation: any) => accommodation.id === accommodationID);

      if (val) {
        return val.rate_plans;
      }

      return [];
    },

    async getNotifications(force: boolean = false) {
      if(force) {
        const response = await ApiService.get('/api/fe/notifications');
        this.notifications.timeline = response.data.timeline;
        this.notifications.assistant = response.data.assistant;
        this.notifications.communications.live_chat_count = response.data.communications.live_chat_count;
        this.notifications.communications.whatsapp_count = response.data.communications.whatsapp_count;
        this.notifications.platform = response.data.platform;
      }

      return this.notifications;
    },

    async getCleanersNotifications(force: boolean = false) {
      if(force) {
        const response = await ApiService.get('/api/fe/notifications/cleaners');
        this.notifications.timeline = response.data.timeline;
        this.notifications.communications.live_chat_count = response.data.communications.live_chat_count;
        this.notifications.platform = response.data.platform;
      }

      return this.notifications;
    },

    async getMaintenancesNotifications(force: boolean = false) {
      if(force) {
        const response = await ApiService.get('/api/fe/notifications/maintenances');
        this.notifications.timeline = response.data.timeline;
        this.notifications.communications.live_chat_count = response.data.communications.live_chat_count;
        this.notifications.platform = response.data.platform;
      }

      return this.notifications;
    },

    async getWelcomersNotifications(force: boolean = false) {
      if(force) {
        const response = await ApiService.get('/api/fe/notifications/welcomers');
        this.notifications.timeline = response.data.timeline;
        this.notifications.communications.live_chat_count = response.data.communications.live_chat_count;
        this.notifications.platform = response.data.platform;
      }

      return this.notifications;
    },

    async getOwnersNotifications(force: boolean = false) {
      if(force) {
        const response = await ApiService.get('/api/fe/notifications/owners');
        this.notifications.timeline = response.data.timeline;
        this.notifications.communications.live_chat_count = response.data.communications.live_chat_count;
        this.notifications.platform = response.data.platform;
      }

      return this.notifications;
    },

    selectPropertyByAccommodations(selectedAccommodation: number) {
      let actualProperty = 0;

      actualProperty = this.propertyID;
      this.properties.forEach((property: any, index: number) => {
        property.accommodations.forEach((accommodation: any) => {
          if (accommodation.id === selectedAccommodation) {
            actualProperty = property.id;
          }
        });
      });

      this.propertyID = actualProperty;

      return actualProperty;
    },

    getFirstAccommodationID() {
      if(this.propertyID == 0) {
        if(!this.firstAccommodationID) {
          this.getProperties();
        }

        return this.firstAccommodationID;
      }

      const accommodations = this.getAccommodations();

      return accommodations[0]?.id;
    },

    setProperty(propertyID: number) {
      this.propertyID = propertyID;
    },

    async getCountries() {
      if (!this.countries) {
        const response = await ApiService.get('/api/fe/countries');
        this.countries = response.data;
      }

      return this.countries;
    },

    toggleLeftMenu() {
      this.menuIsCollapsed = ! this.menuIsCollapsed;
    },

    isLeLeftMenuCollapsed() {
      return this.menuIsCollapsed;
    },

    setGuide(guideFunction) {
      this.guide = guideFunction;
    },

    startGuide() {
      if(this.guide) {
        this.guide();
      }
    },

    handleLogout() {
      this.loading = false;
      this.properties = [];
      this.propertiesMap = [];
      this.propertyID = 0;
      this.firstAccommodationID = 0;
      this.countries = null;
      this.menuIsCollapsed = false;
      this.guide = {};
    },

    hasModule(propertyID: number, module: string) {
      let searchID = this.propertyID;
      if (propertyID) {
        searchID = propertyID;
      }
      if (searchID > 0) {
        const val: any = this.properties.find((property: any) => property.id === searchID);
        if (val) {
          if(val.subscribed_modules) {
            return (val.subscribed_modules[module] === 1);
          }
          return false;
        }
        return false;
      }
      return false;
    },

    changeLiveChatNotification() {
      this.liveChatNotification = !this.liveChatNotification;
    },

    setPageName(name: string) {
      this.pageName = name;
    }
  },
});
