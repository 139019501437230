/* eslint-disable no-underscore-dangle */
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import router from '@/router';
import { useNotifications } from '@/composables/notifications';
import { TokenService } from './token_service';
import useAuthStore from '../stores/auth';
import { useAppStore } from '@/stores/app';
import { AuthService } from '@/services/auth_service';
import { PropertyService } from '@/services/property_service';
import { i18n } from '@/plugins/i18n';

const ApiService = {
  _requestInterceptor: 0,
  _401interceptor: 0,

  init(baseURL: string | undefined) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true;
    // axios.defaults.withXSRFToken = true;
  },

  setHeader() {
    const authStore = useAuthStore();

    axios.defaults.headers.common = {};

    axios.defaults.headers.common['Accept-Language'] = authStore.lang;
    axios.defaults.headers.common['accept'] = 'application/json';

    if (TokenService.isImpersonating()) {
      axios.defaults.headers.common['X-Impersonating'] = true;
      axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getImpersonateToken()}`;
    }
    else {
      axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
    }

  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource: string) {
    return axios.get(resource);
  },

  post(resource: string, data: any) {
    return axios.post(resource, data);
  },

  uploadFile(resource: string, file: any, data: object = {}) {
    const formData = new FormData();
    formData.append('file', file);

    Object.keys(data).forEach((key) => {
      if(data[key] != null) {
        if(typeof data[key] == 'array') {
          formData.append(key, [data[key]]);
        }
        else {
          formData.append(key, data[key]);
        }

      }
      else {
        formData.append(key, null);
      }
    });

    return axios.post(resource, formData);
  },

  put(resource: string, data: any) {
    return axios.put(resource, data);
  },
  patch(resource: string, data: any) {
    return axios.patch(resource, data);
  },

  delete(resource: string, data: object|null = null) {
    if (data) {
      return axios.delete(resource, {
        data: {
          ...data
        }
      });
    }

    return axios.delete(resource);
  },

  customRequest(data: AxiosRequestConfig) {
    return axios(data);
  },

  async downloadFile(resource: string, file_name: string, method: 'GET', data ) {
    try {
      const response = await axios({
        url: resource,
        method,
        responseType: 'blob',
        data
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
      URL.revokeObjectURL(link.href);
    }
    catch (message) {
      return console.error(message);
    }
  },

  async downloadXML(resource: string, file_name: string, method: 'GET', data ) {
    try {
      const response = await axios({
        url: resource,
        method,
        responseType: 'blob',
        data
      });
      const blob = new Blob([response.data], { type: 'application/xml' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
      URL.revokeObjectURL(link.href);
    }
    catch (message) {
      return console.error(message);
    }
  },

  async downloadFilePDF(resource: string, file_name: string, method: 'GET', data) {
    let url = resource;
    if(method === 'GET') {
      const str = qs.stringify({
        ...data
      });

      url = `${resource}?${str}`;
    }
    try {
      const response = await axios({
        url,
        method,
        responseType: 'blob',
        data
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
      URL.revokeObjectURL(link.href);
    }
    catch (message) {
      return console.error(message);
    }
  },

  async downloadFileXLS(resource: string, file_name: string, method: 'GET', data) {
    let url = resource;
    if (method === 'GET') {
      const str = qs.stringify({
        ...data
      });

      url = `${resource}?${str}`;
    }
    try {
      const response = await axios({
        url,
        method,
        responseType: 'blob',
        data
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
      URL.revokeObjectURL(link.href);
    }
    catch (message) {
      return console.error(message);
    }
  },
  // axios({
  //   url: resource,
  //   method,
  //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //   responseType: 'blob',
  //   data
  // }).then((res) => {

  //   const FILE = window.URL.createObjectURL(new Blob([res.data]));

  //   const docUrl = document.createElement('x');
  //   docUrl.href = FILE;
  //   docUrl.setAttribute('download', 'sample.pdf');
  //   document.body.appendChild(docUrl);
  //   docUrl.click();
  // });
  // },

  mountRequestInterceptor() {
    // this._requestInterceptor = axios.interceptors.request.use(async (config) => {
    //   const loading = ElLoading.service({
    //     lock: true,
    //     text: 'Loading',
    //     background: 'rgba(0, 0, 0, 0.7)',
    //   });
    //   // const loading = await loadingController.create({
    //   //   message: 'Please wait...',
    //   // });
    //   // await loading.present();
    //   return config;
    // });
  },

  mount401Interceptor() {
    const {
      errorNotification,
    } = useNotifications();

    this._401interceptor = axios.interceptors.response.use(
      response => response,
      async (error) => {
        const appStore = useAppStore();
        appStore.stopLoading();

        if (error.request.status === 401) {
          const authStore = useAuthStore();
          if (error.config.url.includes('oauth/token')) {
            await authStore.signOut();
            await router.replace({ name: 'login' });

            // await store.dispatch('auth/signOut');
            // throw error;
          }
          else {
            try {
              if (TokenService.isImpersonating()) {

                authStore.unimpersonate()
                  .then(async () => {
                    await nextTick();

                    await AuthService.getUser(true);
                    await PropertyService.getProperties(true);

                    await nextTick();

                    await router.replace({ name: 'login' });
                  })
                  .catch(async (err: any) => {
                  });

                return;
              }
              // await store.dispatch('auth/refreshToken');
              await authStore.refreshToken();
              return this.customRequest({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data,
              });
            }
            catch (e) {
              throw error;
            }
          }
        }

        // Validation Errors on Back-end
        if (error.request.status === 422) {
          const json = error.request.response;

          const errorMessage = this.makeErrorsString(JSON.parse(json));

          errorNotification({ message: errorMessage });
        }

        // Reportable Error
        if (error.request.status === 400) {
          const json = error.request.response;

          let errorMessage = JSON.parse(json);

          if (errorMessage.error !== undefined) {
            errorMessage = errorMessage.message;
          }

          errorNotification({ message: errorMessage });
        }

        // Generic Error
        if (error.request.status === 500) {
          errorNotification({});
        }

        // Generic Error
        if (error.request.status === 503) {
          // errorNotification({});
          await router.replace({ name: '503' });
        }

        throw error;
      },
    );
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  },

  makeErrorsString(errors: any) {
    let errorString = '';

    try {
      Object.entries(errors.errors).forEach(([key, item]: any) => {
        item.forEach((error: string) => {
          errorString += error;
        });
      });
    }
    catch (e) {
      errorString = i18n.global.t('general.error_refresh');
    }

    return errorString;
  },
};

export default ApiService;
