const baseRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Dashboard
    {
      path: 'dashboard',
      component: () => import('../views/DashboardView.vue'),
      name: 'dashboard',
      meta: {
        auth: true,
        permissions: ['dashboard_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Calendars
    {
      path: 'calendars',
      component: () => import('../views/CalendarsView.vue'),
      name: 'calendars',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Assistant
    {
      path: 'assistant',
      component: () => import('../views/AssistantView.vue'),
      name: 'assistant',
      meta: {
        auth: true,
        permissions: ['assistant_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Whats new
    {
      path: 'whats-new',
      component: () => import('../views/WhatsNew.vue'),
      name: 'whatsNew',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Helpdesk
    {
      path: 'helpdesk',
      component: () => import('../views/Helpdesk.vue'),
      name: 'helpdesk',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    {
      path: '/administration',
      component: () => import('../views/administration/administrationView.vue'),
      name: 'administration',
      meta: {
        auth: true,
        permissions: ['super_admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    {
      path: '2fa-status',
      component: () => import('../views/auth/TwoFAStatus.vue'),
      name: '2faStatus',
      meta: {
        auth: true,
        permissions: [],
        // layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default baseRoutes;
