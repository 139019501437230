const baseRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    {
      path: 'subscription',
      component: () => import('@/views/subscription/Subscription.vue'),
      name: 'subscription',
      meta: {
        auth: true,
        permissions: ['view_subscription'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default baseRoutes;
