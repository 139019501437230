import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { usePermissions } from '@/composables/permissions';
import { useNotifications } from '@/composables/notifications';
import { useAppStore } from '@/stores/app';
import useAuthStore from '@/stores/auth';
import { i18n } from '@/plugins/i18n';
import reservationsRoutes from './reservationsRoutes';
import accommodationsRoutes from './accommodationsRoutes';
import authRoutes from './authRoutes';
import baseRoutes from './baseRoutes';
import tasksRoutes from './tasksRoutes';
import ratesRoutes from './ratesRoutes';
import configsRoutes from './configsRoutes';
import messagesRoutes from './messagesRoutes';
import invoicingRoutes from './invoicingRoutes';
import digitalConciergeRoutes from '@/router/digitalConciergeRoutes';
import guestRoutes from './guestRoutes';
import financeRoutes from './financesRoutes';
import statisticsRoutes from './statisticsRoutes';
import timelineRoutes from './timelineRoutes';
import checkInOnlineRoutes from './checkInOnlineRoutes';
import managementRoutes from './managementRoutes';
import subscriptionRoutes from './subscriptionRoutes';
import marketplaceRoutes from './marketplaceRoutes';
import networkingRoutes from './networkingRoutes';
import cleanersRoutes from './cleanersRoutes';
import maintenancesRoutes from './maintenancesRoutes';
import setupForm from './setupForm';
import organization from './organization';
import ownerRoutes from '@/router/ownerRoutes';
import { AuthService } from '@/services/auth_service';
import ownerStatisticsRoutes from '@/router/ownerStatisticsRoutes';
import remindersNotesRoutes from '@/router/remindersNotesRoutes';
import welcomerRoutes from '@/router/welcomerRoutes';
import { useUtils } from '@/composables/utils';

const routes: Array<RouteRecordRaw> = [
  ...authRoutes,
  ...baseRoutes,
  ...reservationsRoutes,
  ...accommodationsRoutes,
  ...tasksRoutes,
  ...ratesRoutes,
  ...configsRoutes,
  ...messagesRoutes,
  ...invoicingRoutes,
  ...digitalConciergeRoutes,
  ...guestRoutes,
  ...financeRoutes,
  ...statisticsRoutes,
  ...timelineRoutes,
  ...checkInOnlineRoutes,
  ...managementRoutes,
  ...marketplaceRoutes,
  ...networkingRoutes,
  ...setupForm,
  ...organization,
  ...subscriptionRoutes,
  ...ownerStatisticsRoutes,

  ...cleanersRoutes,
  ...maintenancesRoutes,
  ...ownerRoutes,
  ...welcomerRoutes,
  ...remindersNotesRoutes,
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      // For testing
      {
        path: 'testing',
        component: () => import('../views/testing/TestingView.vue'),
        name: 'testing',
        meta: {
          auth: true,
          permissions: [],
          // title: 'Dashboard',
          // icon: 'home',
          // keepAlive: true
        },
      },

      // NYI
      {
        path: 'nyi',
        component: () => import('../views/testing/NYIView.vue'),
        name: 'nyi',
        meta: {
          auth: true,
          permissions: [],
          // title: 'Dashboard',
          // icon: 'home',
          // keepAlive: true
        },
      },

      // NYI
      {
        path: '/account-renewal',
        component: () => import('../views/AccountRenewal.vue'),
        name: 'account_renewal',
        meta: {
          auth: true,
          permissions: [],
          // title: 'Dashboard',
          // icon: 'home',
          // keepAlive: true
        },
      },
    ],
  },

  {
    path: '/not-found',
    component: () => import('../views/digitalConcierge/NotFound.vue'),
    name: 'concierge-not-found',
    meta: {
      auth: false,
      permissions: [],
      // layout: 'all',
      // title: 'Dashboard',
      // icon: 'home',
      // keepAlive: true
    },
  },

  {
    path: '/in-maintenance',
    component: () => import('../views/Error503.vue'),
    name: '503',
    meta: {
      auth: false,
      permissions: [],
      // layout: 'all',
      // title: 'Dashboard',
      // icon: 'home',
      // keepAlive: true
    },
  },

  {
    path: '/r/h/:reservationId/:token',
    component: () => import('../views/HomeItRedirect.vue'),
    name: 'homeit',
    meta: {
      auth: false,
      permissions: [],
      // layout: 'all',
      // title: 'Dashboard',
      // icon: 'home',
      // keepAlive: true
    },

    // path: '/r/h/:reservationId/:token',
    // path: '/r/h/aa/bb',
    // beforeEnter: () => {
    //   window.location.href = `https://app.ynnovbooking.com/r/h/${reservationId}/${token}`;
    // },
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    // path: "*",
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/Error404.vue'),
    meta: {
      requiresAuth: false,
      permissions: [],
    },
  },
];

const {
  errorNotification,
} = useNotifications();

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const permissionsCount = Object.keys(to.meta.permissions as any).length;

  const appStore = useAppStore();

  // const routePermissions = to.meta.permissions

  // ###########
  // Processing after successful login TAGVIEW And breadcrumbs
  // handleTagViewAndBreadcrumbsAndKeepAlive(to);
  // Simulated acquisition token
  // sessionStorage.setItem('access_token', 123);

  const token = localStorage.getItem('access_token');
  // const userRole = sessionStorage.getItem('user_role');
  // exist token Description already logged in
  if (token && to.meta.auth) {
    // Get Notifications

    const user = await AuthService.getUser();
    const role = user?.role;
    const authStore = useAuthStore();
    const { getUserPermissionMap } = useUtils();

    // Notifications
    try {
      switch (role) {
        case 'cleaner':
          appStore.getCleanersNotifications(true);
          break;
        case 'maintenance':
          appStore.getMaintenancesNotifications(true);
          break;
        case 'welcomer':
          appStore.getWelcomersNotifications(true);
          break;
        case 'owner':
          appStore.getOwnersNotifications(true);
          break;
        default:
          if(authStore.hasPermission('assistant_view')) {
            appStore.getNotifications(true);
          }
          break;
      }
    }
    catch (error) {
      // to prevent errors
    }

    // You cannot access the login interface after logging in
    if (to.path === '/login') {
      const page = getUserPermissionMap(authStore.role);
      next({ name: page.route });
      return;
    }

    // ######################################################

    const bannedAccessibleManager = [
      'subscription',
      'logout',
    ];

    const bannedAccessibleUsers = [
      'logout',
      'account_renewal',
    ];

    if (user.active === 0 && role === 'manager' && !bannedAccessibleManager.includes(to.name)) {
      next({ name: 'subscription' });
      return;
    }

    if (user.active === 0 && role === 'user' && !bannedAccessibleUsers.includes(to.name)) {
      next({ name: 'account_renewal' });
      return;
    }

    if (role === 'owner' || role === 'cleaner' || role === 'maintenance' || role === 'welcomer') {
      if (role === 'owner' && to.meta.layout !== 'owner' && to.meta.layout !== 'all') {
        next({ name: 'owner.dashboard' });
        return;
      }
      if (role === 'cleaner' && to.meta.layout !== 'cleaner' && to.meta.layout !== 'all') {
        next({ name: 'housekeeping.housekeeping_list' });
        return;
      }
      if (role === 'maintenance' && to.meta.layout !== 'maintenance' && to.meta.layout !== 'all') {
        next({ name: 'maintenances.maintenance' });
        return;
      }
 		  if (role === 'welcomer' && to.meta.layout !== 'welcomer' && to.meta.layout !== 'all') {
        next({ name: 'welcomer.dashboard' });
        return;
      }
    }

    if (role === 'user') {
      if (to.meta.layout === 'owner' || to.meta.layout === 'cleaner' || to.meta.layout === 'maintenance' || to.meta.layout === 'welcomer') {
        const page = getUserPermissionMap(authStore.role);
        next({ name: page.route });
      }
    }
    // ######################################################

    // // There are user permissions, and the route is not empty, let it go
    if (permissionsCount === 0) {
      next();
    }
    else {
      const permissionsUtils = usePermissions();
      const hasPermissions = await permissionsUtils.hasPermissions(to.meta.permissions);

      if (!hasPermissions) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errorNotification({ message: i18n.global.t('general.error_no_access') });
        const page = getUserPermissionMap(authStore.role);
        next({ name: page.route });
      }
      else {
        next();
      }
      // ----------------------------------------------------------------------------------------------------
      // Simulate when user permissions do not exist, get user permissions
      // const userRole = sessionStorage.getItem('user_role');
      // And set the corresponding route according to the permissions
      // store.commit('SET_ROLES_AND_ROUTES', userRole);
      // // If prompted addRoutes Deprecated, use spread operator to complete the operation
      // // router.addRoute(...store.getters.getRoutes)
      // router.addRoutes(store.getters.getRoutes);
      // // if addRoutes Not completed, the routing guard will execute it again
      // next({ ...to, replace: true });
    }
    // if (userRole && store.getters.getRoutes.length) {
    //   next();
    // } else {
    // // Simulate when user permissions do not exist, get user permissions
    //   const userRole = sessionStorage.getItem('user_role');
    //   // And set the corresponding route according to the permissions
    //   store.commit('SET_ROLES_AND_ROUTES', userRole);
    //   // If prompted addRoutes Deprecated, use spread operator to complete the operation
    //   // router.addRoute(...store.getters.getRoutes)
    //   router.addRoutes(store.getters.getRoutes);
    //   // if addRoutes Not completed, the routing guard will execute it again
    //   next({ ...to, replace: true });
    // }
  }
  else if (to.meta.auth) {
    next({ name: 'login' });
  }
  else {
    if (token && to.path === '/login') {
      await AuthService.getUser();
      const authStore = useAuthStore();
      const { getUserPermissionMap } = useUtils();

      const page = getUserPermissionMap(authStore.role);
      next({ name: page.route });
      return;
    }
    next();
  }
  // else {
  // // If you want to go to a route that does not require authorization, let it go
  //   if (constantRoutes.some((item) => item.path === to.path)) {
  //     next();
  //   } else {
  //     next({ path: '/logon' });
  //   }
  // }
  // ###########

  // const login = false;
  // if (to.meta.auth && !login) {
  //   next({ name: 'login' });
  // } else {
  //   next();
  // }

  // Reset Guide information
  appStore.setGuide(null);
});

export default router;
