const maintenancesRoutes = [{
  path: '/maintenance',
  component: () => import('@/layouts/MainLayout.vue'),
  children: [
    // List
    {
      path: '/maintenance/tasks/maintenance',
      component: () => import('@/views/tasks/Maintenance.vue'),
      name: 'maintenances.maintenance',
      meta: {
        auth: true,
        permissions: ['maintenance_maintenance_view'],
        title: 'List',
        layout: 'maintenance',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Statistics
    {
      path: '/maintenance/statistics',
      component: () => import('@/components/statistics/tasks/Maintenance.vue'),
      name: 'maintenances.statistics',
      meta: {
        auth: true,
        permissions: ['maintenance_stats_tasks_maintenance_view'],
        title: 'Statistics',
        layout: 'maintenance',
        // icon: 'home',
        // keepAlive: true
      },
    },
    //  Live-Chat
    {
      path: '/maintenance/communications/live-chat',
      component: () => import('@/components/schedulers/Maintenances/MaintenanceLiveChat.vue'),
      name: 'maintenances.live_chat',
      meta: {
        auth: true,
        permissions: ['maintenance_communications_livechat_view'],
        title: 'Live Chat',
        layout: 'maintenance',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Networking
    {
      path: '/maintenance/networking',
      component: () => import('../views/networking/NetworkingIndex.vue'),
      name: 'maintenances.networking',
      meta: {
        auth: true,
        permissions: ['maintenance_networking_view'],
        title: 'Networking',
        layout: 'maintenance',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Marketplace
    {
      path: '/maintenance/marketplace',
      component: () => import('../views/marketplace/MarketplaceIndex.vue'),
      name: 'maintenances.marketplace',
      meta: {
        auth: true,
        permissions: ['maintenance_marketplace_view'],
        title: 'Marketplace',
        layout: 'maintenance',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Timeline
    {
      path: '/maintenance/timeline',
      component: () => import('../views/TimelineView.vue'),
      name: 'maintenances.timeline',
      meta: {
        auth: true,
        permissions: [],
        title: 'Timeline',
        layout: 'maintenance',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Reminder && Notes
    {
      path: '/maintenance/reminders',
      component: () => import('../views/remindersView.vue'),
      name: 'maintenances.reminders',
      meta: {
        auth: true,
        permissions: [],
        layout: 'maintenance',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/maintenance/notes',
      component: () => import('../views/notesView.vue'),
      name: 'maintenances.notes',
      meta: {
        auth: true,
        permissions: [],
        layout: 'maintenance',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default maintenancesRoutes;
