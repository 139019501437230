<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ApiService from '@/services/api_service';
import useAuthStore from '@/stores/auth';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import auth from "@/stores/auth";
import {i18n} from "@/plugins/i18n";

const props = defineProps({
  reminders: {
    id: { type: Number, default: null },
    property_id: { type: Number, default: null },
    title: { type: Number, default: '' },
    description: { type: Number, default: '' },
    date: { type: String, default: '' },
    hour: { type: String, default: '' }
  }
});

/* const { t } = useI18n(); */
const authStore = useAuthStore();

const notificationsRefs = ref([]);
const acknowledged_arr = ref([]);
const shownNotifications = ref([]);

onMounted(async () => {
  setTimeout(async () => {
    await nextTick();
    await nextTick();

    props.reminders.forEach((reminder) => {
      setTimeout(async () => {
        notificationsRefs.value[reminder.id].style.right = '0px';
      }, 750);
    });
  }, 500);
});

const acknowledge = async (reminder: object) => {
  let response;
  switch (authStore.role) {
    case 'owner':
    case 'welcomer':
    case 'maintenance':
    case 'cleaner':
      response = await ApiService.post(`/api/fe/portals/reminders/${reminder.id}/acknowledge`, {});
      break;
    default:
      response = await ApiService.post(`/api/fe/schedulers/reminders/${reminder.property_id}/${reminder.id}/acknowledge`, {});
      break;
  }

  if(response.status === 200){
    setTimeout(async () => {
      await nextTick();
      await nextTick();

      acknowledged_arr.value[reminder.id] = true;
      notificationsRefs.value[reminder.id].style.right = '-580px';

      setTimeout(async () => {
        notificationsRefs.value[reminder.id].style.display = 'none';
      }, 500);
    }, 500);
  }
};

const snooze = async (reminder: object, period: string) => {
  let response;
  switch (authStore.role) {
    case 'owner':
    case 'welcomer':
    case 'maintenance':
    case 'cleaner':
      response = await ApiService.post(`/api/fe/portals/reminders/${reminder.id}/snooze`, { period });
      break;
    default:
      response = await ApiService.post(`/api/fe/schedulers/reminders/${reminder.property_id}/${reminder.id}/snooze`, { period });
      break;
  }

  if(response.status === 200){
    setTimeout(async () => {
      await nextTick();
      await nextTick();

      acknowledged_arr.value[reminder.id] = true;
      notificationsRefs.value[reminder.id].style.right = '-580px';

      setTimeout(async () => {
        notificationsRefs.value[reminder.id].style.display = 'none';
      }, 500);
    }, 500);
  }
};

watch( notificationsRefs.value, () => {
  props.reminders.forEach((reminder) => {
    if(shownNotifications.value.findIndex(shownReminder => shownReminder.id === reminder.id) === -1){
      notificationsRefs.value[reminder.id].style.right = '-580px';

      setTimeout(async () => {
        notificationsRefs.value[reminder.id].style.right = '0px';
      }, 500);

      shownNotifications.value.push(reminder);
    }
  });
});

const getTranslatedLabel = (val: number) => {
  switch(val) {
    case 1:
      if(auth().lang == 'pt') {
        return 'Ignorar por 1 hora';
      } else if(auth().lang == 'en') {
        return 'Ignore for 1 hour';
      } else if(auth().lang == 'fr') {
        return 'Ignorer pendant 1 heure';
      } else if(auth().lang == 'es') {
        return 'Ignorar durante 1 hora';
      }
      break;
    case 4:
      if(auth().lang == 'pt') {
        return 'Ignorar por 4 horas';
      } else if(auth().lang == 'en') {
        return 'Ignore for 4 hours';
      } else if(auth().lang == 'fr') {
        return 'Ignorer pendant 4 heures';
      } else if(auth().lang == 'es') {
        return 'Ignorar durante 4 horas';
      }
      break;
    case 24:
      if(auth().lang == 'pt') {
        return 'Ignorar por 24 horas';
      } else if(auth().lang == 'en') {
        return 'Ignore for 24 hours';
      } else if(auth().lang == 'fr') {
        return 'Ignorer pendant 24 heures';
      } else if(auth().lang == 'es') {
        return 'Ignorar durante 24 horas';
      }
      break;
    case 999:
      if(auth().lang == 'pt') {
        return 'Descartar';
      } else if(auth().lang == 'en') {
        return 'Discard';
      } else if(auth().lang == 'fr') {
        return 'Jeter';
      } else if(auth().lang == 'es') {
        return 'Desechar';
      }
      break;
  }
};
</script>

<template>
  <div class="reminders-container">
    <div v-for="(reminder, index) in reminders" :ref="(el) => (notificationsRefs[reminder.id] = el)" :key="index" class="reminder-notification">
      <div class="close-notification-container">
        <FontAwesomeIcon :icon="['fal', 'circle-xmark']" class="light close-notification" />
        <FontAwesomeIcon :icon="['fas', 'circle-xmark']" class="solid hidden close-notification" @click="acknowledge(reminder)" />
      </div>
      <span class="reminder-title">{{ reminder.title }}</span>
      <div class="flex flex-row justify-between gap-2 max-w-[500px] !w-full items-center">
        <span class="reminder-description max-w-[375px] whitespace-nowrap overflow-hidden overflow-ellipsis">
          <template v-if="reminder.description">{{ reminder.description }} <br></template>
          {{ `${reminder.date} &bull; ${reminder.hour}h` }}
        </span>

        <el-select
          class="snooze-select !w-24 min-w-24 !fs-11"
          popper-class="snooze-select-popper"
          size="small"
          :placeholder="i18n.global.t('scheduler.reminders.snooze')"
        >
          <el-option
            :value="1"
            :label="i18n.global.t('scheduler.reminders.snooze_1_hour')"
            @click="snooze(reminder, 1)"
          />
          <el-option
            :value="4"
            :label="i18n.global.t('scheduler.reminders.snooze_4_hours')"
            @click="snooze(reminder, 4)"
          />
          <el-option
            :value="24"
            :label="i18n.global.t('scheduler.reminders.snooze_24_hours')"
            @click="snooze(reminder, 24)"
          />
          <el-option
            :value="24"
            :label="i18n.global.t('scheduler.reminders.discard')"
            @click="snooze(reminder, 999)"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<style scoped>
.reminders-container{
  width: fit-content;
  max-height: 700px;
  top: 60px;
  right: 24px;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
}

.reminder-notification{
  position: relative;
  width: fit-content;
  max-width: 500px;
  min-width: 350px;
  padding: 10px;
  border-radius: 4px;
  align-items: start;
  right: -680px;
  transition: all 350ms;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #F1C2D399;
  background-color: #fff7fb;
  border-left: 3px solid #D0195C;
}

.reminder-title{
  text-wrap: none;
  font-size: 13px;
  font-weight: 400;
  color: #D0195C;
}

.reminder-description{
  font-size: 11px;
  position: relative;
}

.close-notification{
  position: absolute;
  right: 5px;
  top: 5px;
  color: #D0195C !important;
  font-size: 12px;

  &:hover{
    cursor: pointer;
  }
}
</style>
