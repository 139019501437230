const baseRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Guests
    {
      path: 'guests',
      component: () => import('../views/guests/GuestsView.vue'),
      name: 'guests',
      meta: {
        auth: true,
        permissions: ['guest_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];
  
export default baseRoutes;
  