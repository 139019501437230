import { createHead } from '@vueuse/head';
import { createPinia } from 'pinia';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment';
import ApiService from '@/services/api_service';
import { PropertyService } from '@/services/property_service';
import { AuthService } from '@/services/auth_service';
import { TokenService } from '@/services/token_service';
import { EchoService } from '@/services/web_socket';
import { useFontAwesome } from './composables/useFontAwesome';
import { i18n } from './plugins/i18n';
import ganttastic from '@ynnov/ynnov-gantt';
import ContextMenu from '@imengyu/vue3-context-menu';
import ClickOutside from '@/directives/ClickOutsideDirective';
import { createApp } from 'vue';
import App from './App.vue';
import './assets/index.postcss';
import router from './router';
import VuePapaParse from 'vue-papa-parse';
import 'element-plus/theme-chalk/src/index.scss';
import 'element-plus/theme-chalk/dark/css-vars.css';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import 'dayjs/locale/pt';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import VueVectorMap from 'vuevectormap';
import * as Sentry from '@sentry/vue';
// import 'vuevectormap/src/scss/vuevectormap.scss';

// Import your preferred map
import 'jsvectormap/dist/maps/world';

import './assets/main.css';
import './assets/dark_theme.scss';
import './assets/overwrites.scss';

import 'animate.css';

const { addIcons } = useFontAwesome();
addIcons();
const head = createHead();
// const pusher = new Pusher();

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
app.use(createPinia());
app.use(VueAxios, axios);
app.use(i18n);
app.use(head);
app.use(VuePapaParse);
app.use(ganttastic);
app.use(ContextMenu);
app.use(router);
app.use(VueVectorMap, {
  zoomOnScrollSpeed: 1,
  zoomStep: 1.3,
});
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.component('FontAwesomeLayersText', FontAwesomeLayersText);
app.provide('axios', app.config.globalProperties.axios); // provide 'axios'
app.directive('click-outside', ClickOutside);

// Dates globalProperties
app.config.globalProperties.$formatter = {
  date(date: string) {
    if (date) {
      return moment(String(date)).format('DD/MM/YYYY');
    }
    return date;
  },
  dateTime(date: string) {
    if (date) {
      return moment(String(date)).format('DD/MM/YYYY HH:mm');
    }
    return date;
  },
  time(date: string) {
    if (date) {
      return moment(String(date)).format('HH:mm');
    }
    return date;
  },
  currency(amount: number, language = 'pt-PT') {
    const format = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    }).format(amount).replace(/\s+€/, '€');

    return format.replace(',', '.');
  },
  floatFormat(amount: number, language = 'pt-PT') {
    const format = new Intl.NumberFormat(language, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    }).format(amount);

    return format.replace(',', '.');
  },
  intFormat(amount: number, language = 'pt-PT') {
    const format = new Intl.NumberFormat(language, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true
    }).format(amount);

    return format;
  }
};

ApiService.init(import.meta.env.VITE_APP_ROOT_API);

const getInfo = async () => {
  ApiService.setHeader();
  ApiService.mountRequestInterceptor();
  ApiService.mount401Interceptor();

  await PropertyService.getProperties();

  await EchoService.connect();
};

if (TokenService.getToken()) {
  getInfo();
}

app.mount('#app');
