import Echo from 'laravel-echo';
import Pusher from 'pusher-js/with-encryption';
import { TokenService } from '@/services/token_service';
import { useAppStore } from '@/stores/app';
import { AuthService } from '@/services/auth_service';
import useAuthStore from '@/stores/auth';
import ApiService from '@/services/api_service';
import { createVNode, h, render } from 'vue';
import Reminder from '@/components/reminders/Reminders.vue';
import { useNotifications } from '@/composables/notifications';
import { i18n } from '@/plugins/i18n';
import Reminders from '@/components/reminders/Reminders.vue';
import RemindersContainer from '@/components/reminders/RemindersContainer.vue';

const {
  errorNotification,
  notification,
  confirmNotification,
} = useNotifications();

const reminders = ref([]);

const EchoService = {
  async connect() {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    await nextTick();

    window.Pusher = Pusher;

    window.Echo = new Echo({
      broadcaster: 'reverb',
      authEndpoint: `${import.meta.env.VITE_APP_ROOT_API}broadcasting/auth`,
      httpHost: import.meta.env.VITE_REVERB_HOST,
      httpPath: '',
      httpPort: import.meta.env.VITE_REVERB_PORT,
      key: import.meta.env.VITE_REVERB_APP_KEY,
      wsHost: import.meta.env.VITE_REVERB_HOST,
      wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
      wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
      forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
      encryptionMasterKeyBase64: import.meta.env.VITE_REVERB_ENCRYPTION_KEY,
      auth: {
        headers: {
          Authorization: `Bearer ${TokenService.getToken()}`
        },
      },
    });

    const user = await AuthService.getUser();
    const permissions = await AuthService.getPermissions();

    window.Echo.encryptedPrivate(`${import.meta.env.VITE_REVERB_PREFIX}.user.${user.id}`)
      .listen('.channels', (channels) => {
        this.connectToChannels(channels);
      })
      .listen('.reminders', (data) => {
        if(
          ((authStore.role === 'manager' || authStore.role === 'user') && !TokenService.isImpersonating() && Object.keys(permissions).findIndex(key => key === 'communications_reminders_view') !== -1)
        ) {
          if(reminders.value.findIndex(obj => obj.id === data[0].id) === -1){
            reminders.value.push(data[0]);
            render(h(RemindersContainer, { reminders: reminders.value }), document.body.querySelector('.reminders'));
          }
        }
      })
      .listen('.messages', (message) => {
        switch (message.type) {
          case 'new_message':
            if(!appStore.liveChatNotification) {
              // notification({
              //   title: 'Live Chat',
              //   type: 'info',
              //   message: i18n.global.t('live_chat.messages_to_read'),
              //   duration: 0,
              //   onClose: appStore.changeLiveChatNotification
              // });
              appStore.changeLiveChatNotification();
            }
            if(message.actions === 'get_notifications'){
              switch (authStore.role) {
                case 'cleaner':
                  appStore.getCleanersNotifications(true);
                  break;
                case 'welcomer':
                  appStore.getWelcomersNotifications(true);
                  break;
                case 'owner':
                  appStore.getOwnersNotifications(true);
                  break;
              }
            }
            break;
          case 'new_message_maintenances':
            if(!appStore.liveChatNotification) {
              // notification({
              //   title: 'Live Chat',
              //   type: 'info',
              //   message: i18n.global.t('live_chat.messages_to_read'),
              //   duration: 0,
              //   onClose: appStore.changeLiveChatNotification
              // });
              appStore.changeLiveChatNotification();
            }
            if(message.actions === 'get_notifications'){
              appStore.getMaintenancesNotifications(true);
            }
            break;
          case 'update_notifications':
            if(message.actions === 'get_notifications'){
              appStore.getCleanersNotifications(true);
            }
            break;
          case 'maintenances_update_notifications':
            if(message.actions === 'get_notifications'){
              appStore.getMaintenancesNotifications(true);
            }
            break;
          case 'welcomers_update_notifications':
            if(message.actions === 'get_notifications'){
              appStore.getWelcomersNotifications(true);
            }
            break;
          case 'owners_update_notifications':
            if(message.actions === 'get_notifications'){
              appStore.getOwnersNotifications(true);
            }
            break;
        }
      })
      .listen('.user_info', (action) => {
        switch(action[0]){
          case 'refresh':
            AuthService.getUser(true);
            break;
        }
      });

    setTimeout(async () => {
      await ApiService.get('/api/fe/broadcast-channels');
    }, 3000);
  },

  disconnect() {
    window.Echo.disconnect();
  },

  async connectToChannels(channels) {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    // Property
    let delay = 500;
    for (const [channel, value] of Object.entries(channels.property)) {
      setTimeout(() => {
        window.Echo.private(channel)
          .listen('.Notification', (data: JSON) => {
            switch (data.task) {
              case 'notifications_update':
                if(authStore.hasPermission('assistant_view')) {
                  appStore.getNotifications(true);
                }
                break;
            }
          });
      }, delay);

      delay += 500;
    }

    // whatsapp
    delay = 500;
    for (const [channel, value] of Object.entries(channels.whatsapp)) {
      setTimeout(() => {
        window.Echo.private(channel)
          .listen('.Messages', (data) => {
            switch (data.type) {
              case 'new_message_received':
                if(authStore.hasPermission('assistant_view')) {
                  appStore.getNotifications(true);
                }
                break;
              case 'disconnected':
                notification({
                  title: 'Whatsapp',
                  type: 'warning',
                  message: i18n.global.t('scheduler.whatsapp.notifications.disconnected')
                });
                break;
              case 'auth_failure':
                notification({
                  title: 'Whatsapp',
                  type: 'warning',
                  message: i18n.global.t('scheduler.whatsapp.notifications.auth_failure')
                });
                break;
              case 'authenticated':
                notification({
                  title: 'Whatsapp',
                  type: 'success',
                  message: i18n.global.t('scheduler.whatsapp.notifications.authenticated')
                });
                break;
              case 'ready':
                notification({
                  title: 'Whatsapp',
                  type: 'success',
                  message: i18n.global.t('scheduler.whatsapp.notifications.ready')
                });
                break;
            }
          });
      }, delay);
      delay += 1000;
    }

    // chat
    delay = 500;
    for (const [channel, value] of Object.entries(channels.chat)) {
      setTimeout(() => {
        window.Echo.join(channel)
          .here((users) => {
            // list of all connected user to the channel
          })
          .joining((user) => {
            // new user join the channel
          })
          .leaving((user) => {
            // User leav the channel
          })
          .error((error) => {
            // Error!!
            console.error(error);
          }).listen('.NewMessage', (message) => {
          });
      }, delay);

      delay += 1000;
    }
  },
};

export { EchoService };
