const baseRoutes = [{
  path: '/timeline',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Guests
    {
      path: '',
      component: () => import('../views/TimelineView.vue'),
      name: 'timeline',
      meta: {
        auth: true,
        permissions: ['timeline_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    
  ],
}];
    
export default baseRoutes;

