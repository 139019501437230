import tinycolor from 'tinycolor2';
import useAuthStore from '@/stores/auth';

export function useUtils() {
  // Accommodations
  const currencyFormatter = (amount: number, language = 'pt-PT') => {
    const format = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    }).format(amount).replace(/\s+€/, '€');

    return format.replace(',', '.');
  };

  const isNumeric = (val: string) => /^[0-9]+$/.test(val);

  const isDecimal = (val: string) => /^[0-9]+\.?[0-9]?[0-9]?$/.test(val);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // const round = (num: number) => {
  //   return Math.round((num + Number.EPSILON) * 100) / 100;
  // };

  const round = (num: number, decimalPlaces: number = 0) => {
    const p = 10**(decimalPlaces || 0);
    const n = (num * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
  };

  const floatFormat = (amount: number, decimalPlaces: number, language = 'pt-PT') => {
    const format = new Intl.NumberFormat(language, {
      style: 'decimal',
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      useGrouping: true
    }).format(amount);

    return format.replace(',', '.');
  };

  const hexDarken = (color: string, percentage: number, withDash: boolean = true) => {
    let newColor = tinycolor(color).darken(percentage).toString();

    if (!withDash) {
      newColor = newColor.substring(1);
    }

    return newColor;
  };

  const getUserPermissionMap = (userRole: string) => {
    const authStore = useAuthStore();
    let permissionRouteMap = [];
    switch (userRole) {
      case 'owner':
        permissionRouteMap = [
          { permission: 'owner_dashboard_view', route: 'owner.dashboard' },
          { permission: 'owner_reservation_view', route: 'owner.reservation.map' },
          { permission: 'owner_reservation_view', route: 'owner.reservation.index' },
          { permission: 'owner_view_ci_co_page', route: 'owner.check_in_out' },
          { permission: 'owner_invoicing_view', route: 'owner.invoices.issued' },
          { permission: 'owner_calendar_view', route: 'owner.rates.edit' },
          { permission: 'owner_accommodation_view', route: 'owner.accommodation.index' },
          { permission: 'owner_finance_expenses_view', route: 'owner.finances.expenses' },
          { permission: 'owner_finance_invoices_view', route: 'owner.finances.invoices' },
          { permission: 'owner_owners_view', route: 'owner.owners.owner' },
          { permission: 'owner_stats_indicators_view', route: 'owner.statistics.indicators' },
          { permission: 'owner_stats_reservations_view', route: 'owner.statistics.reservations' },
          { permission: 'owner_communications_livechat_view', route: 'owner.live_chat' },
          { permission: 'owner_marketplace_view', route: 'owner.marketplace' },
        ];
        break;
      case 'welcomer':
        permissionRouteMap = [
          { permission: 'welcomer_dashboard_view', route: 'welcomer.dashboard' },
          { permission: 'welcomer_reservation_view', route: 'welcomer.reservation.index' },
          { permission: 'welcomer_view_ci_co_page', route: 'tasks.check_in_outs' },
          { permission: 'welcomer_sef_view', route: 'welcomer.sef' },
          { permission: 'welcomer_maintenance_view', route: 'welcomer.maintenance' },
          { permission: 'welcomer_ci_online_view', route: 'welcomer.digitalConcierge' },
          { permission: 'welcomer_communications_livechat_view', route: 'welcomer.live_chat' },
          { permission: 'welcomer_marketplace_view', route: 'welcomer.marketplace' },
          { permission: 'welcomer_networking_view', route: 'welcomer.networking' },
        ];
        break;
      case 'cleaner':
        permissionRouteMap = [
          { permission: 'housekeeping_housekeeping_view', route: 'housekeeping.housekeeping_list' },
          { permission: 'housekeeping_maintenance_view', route: 'housekeeping.maintenance' },
          { permission: 'housekeeping_stats_tasks_housekeeping_view', route: 'housekeeping.statistics' },
          { permission: 'housekeeping_communications_livechat_view', route: 'housekeeping.live_chat' },
          { permission: 'housekeeping_marketplace_view', route: 'housekeeping.marketplace' },
          { permission: 'housekeeping_networking_view', route: 'housekeeping.networking' },
        ];
        break;
      case 'maintenance':
        permissionRouteMap = [
          { permission: 'maintenance_maintenance_view', route: 'maintenances.maintenance' },
          { permission: 'maintenance_stats_tasks_maintenance_view', route: 'maintenances.statistics' },
          { permission: 'maintenance_communications_livechat_view', route: 'maintenances.live_chat' },
          { permission: 'maintenance_marketplace_view', route: 'maintenances.marketplace' },
          { permission: 'maintenance_networking_view', route: 'maintenances.networking' }
        ];
        break;
      default:
        permissionRouteMap = [
          { permission: 'dashboard_view', route: 'dashboard' },
          { permission: 'reservation_view', route: 'reservations.map' },
          { permission: 'view_ci_co_page', route: 'tasks.check_in_outs' },
          { permission: 'housekeeping_view', route: 'tasks.cleaning' },
          { permission: 'sef_view', route: 'tasks.sef' },
          { permission: 'maintenance_view', route: 'tasks.maintenance' },
          { permission: 'guest_view', route: 'guests' },
          { permission: 'invoicing_view', route: 'invoicing.issued' },
          { permission: 'ci_online_view', route: 'tasks.check_in_online' },
          { permission: 'calendar_view', route: 'rates.price_map' },
          { permission: 'accommodation_view', route: 'accommodation.index' },
          { permission: 'finance_profitability_view', route: 'finance.rentability' },
          { permission: 'finance_income_view', route: 'finance.income' },
          { permission: 'finance_expenses_view', route: 'finance.expenses' },
          { permission: 'finance_invoices_view', route: 'finance.invoices' },
          { permission: 'finance_payments_view', route: 'finance.payments' },
          { permission: 'owners_view', route: 'owners.owner' },
          { permission: 'owner_finance_expenses_view', route: 'finance' },
          { permission: 'stats_indicators_view', route: 'indicators' },
          { permission: 'stats_reservations_view', route: 'reservation' },
          { permission: 'stats_tasks_housekeeping_view', route: 'task' },
          { permission: 'stats_tasks_staff_view', route: 'task' },
          { permission: 'stats_tasks_maintenance_view', route: 'task' },
          { permission: 'stats_inst_sef_view', route: 'institutional' },
          { permission: 'stats_inst_tmt_view', route: 'institutional' },
          { permission: 'stats_inst_ine_srea_edit', route: 'institutional' },
          { permission: 'assistant_view', route: 'assistant' },
          { permission: 'communications_reminders_view', route: 'reminders' },
          { permission: 'communications_notes_view', route: 'notes' },
          { permission: 'communications_emails_view', route: 'messages.emails' },
          { permission: 'communications_sms_view', route: 'messages.sms' },
          { permission: 'communications_livechat_view', route: 'messages.live_chat' },
          { permission: 'communications_whatsapp_view', route: 'messages.whatsapp' },
          { permission: 'timeline_view', route: 'timeline' },
          { permission: 'marketplace_view', route: 'marketplace.index' },
          { permission: 'networking_view', route: 'networking' }
        ];
        break;
    }
    // Itera sobre o mapa e encontra a primeira página a que o usuário tem acesso
    return permissionRouteMap.find(entry => authStore.hasPermission(entry.permission));
  };

  // Return
  return {
    currencyFormatter,
    isNumeric,
    isDecimal,
    round,
    floatFormat,
    hexDarken,
    validateEmail,
    getUserPermissionMap
  };
}
