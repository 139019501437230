const baseRoutes = [{
  path: '/messages',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Redirect to Dashboard
    {
      path: '',
      redirect: 'messages/emails',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'emails',
      component: () => import('../views/messages/emailsView.vue'),
      name: 'messages.emails',
      meta: {
        auth: true,
        permissions: ['communications_emails_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'sms',
      component: () => import('../views/messages/smsView.vue'),
      name: 'messages.sms',
      meta: {
        auth: true,
        permissions: ['communications_sms_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'livechat',
      component: () => import('../views/messages/liveChatView.vue'),
      name: 'messages.live_chat',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'whatsapp',
      component: () => import('../views/messages/whatsappView.vue'),
      name: 'messages.whatsapp',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default baseRoutes;
