const networkingRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Management
    {
      path: '/networking',
      component: () => import('../views/networking/NetworkingIndex.vue'),
      name: 'networking',
      meta: {
        auth: true,
        permissions: ['networking_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default networkingRoutes;
