const reservationsRoutes = [{
  path: '/accommodations',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Accommodation Index
    {
      path: '',
      component: () => import('../views/accommodations/AccommodationsListView.vue'),
      name: 'accommodation.index',
      meta: {
        auth: true,
        permissions: ['accommodation_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Accommodation Edit
    {
      path: ':accommodationId/edit',
      component: () => import('../views/accommodations/AccommodationsEditView.vue'),
      name: 'accommodation.edit',
      meta: {
        auth: true,
        permissions: ['accommodation_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default reservationsRoutes;
