const tasksRoutes = [{
  path: '/tasks',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Cleaning
    {
      path: '/tasks/housekeeping',
      component: () => import('../views/tasks/CleaningsView.vue'),
      name: 'tasks.cleaning',
      meta: {
        auth: true,
        permissions: ['housekeeping_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // SEF
    {
      path: '/tasks/sef',
      component: () => import('../views/sef/ListView.vue'),
      name: 'tasks.sef',
      meta: {
        auth: true,
        permissions: ['sef_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Check-in-outs
    {
      path: '/tasks/check-in-outs',
      component: () => import('../views/tasks/CheckInOuts.vue'),
      name: 'tasks.check_in_outs',
      meta: {
        auth: true,
        permissions: ['view_ci_co_page'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Maintenance
    {
      path: '/tasks/maintenance',
      component: () => import('../views/tasks/Maintenance.vue'),
      name: 'tasks.maintenance',
      meta: {
        auth: true,
        permissions: ['maintenance_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default tasksRoutes;
