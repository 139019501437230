const managementRoutes = [{
  path: '/management',
  component: () => import('@/layouts/ManagementLayout.vue'),
  children: [
    // Management
    {
      path: '/management',
      component: () => import('@/views/ManagementView.vue'),
      name: 'management',
      meta: {
        auth: true,
        permissions: ['admin'],
      },
    },
    // Accommodations Linked
    {
      path: '/management/accommodations/link',
      component: () => import('@/components/management/accommodations/AccommodationsLinked.vue'),
      name: 'linked_accommodations',
      meta: {
        auth: true,
        permissions: ['admin'],
      },
    },
    {
      path: '/management/accommodations/addedit',
      component: () => import('../components/management/ratePlans/ratePlansAddEdit.vue'),
      name: 'add_edit',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/accommodations/otasdifferential',
      component: () => import('@/components/management/accommodations/OtasDifferential.vue'),
      name: 'otas_differential',
      meta: {
        auth: true,
        permissions: ['admin'],
      },
    },
    {
      path: '/management/accommodations/websitepromotions',
      component: () => import('@/components/management/accommodations/WebsitePromotions.vue'),
      name: 'Website Promotions',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/vrbo/vrbo',
      component: () => import('@/components/management/OTAS/vrbo/vrboAddICAL.vue'),
      name: 'vrbo_add_ical',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/tripadvisor/tripadvisor',
      component: () => import('@/components/management/OTAS/tripAdvisor/tripAdvisorAddICAL.vue'),
      name: 'tripadvisor_add_ical',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/accommodations/sendtowebsite',
      component: () => import('../components/management/accommodations/SendToWebsite.vue'),
      name: 'SEND TO WEBSITE',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/sendAllRatesToCM',
      component: () => import('../components/management/CHM/SendRatePlans.vue'),
      name: 'Send Rate Plans',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/accommodations/remove',
      component: () => import('../components/management/accommodations/Remove.vue'),
      name: 'REMOVE',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/rateplans/pricesvalues',
      component: () => import('../components/management/ratePlans/pricesValues.vue'),
      name: 'prices_values',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/rateplans/pricespercentage',
      component: () => import('../components/management/ratePlans/pricesPercentage.vue'),
      name: 'prices_percentage',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/plumguide/plumguide',
      component: () => import('../components/management/OTAS/plumguide/plumGuideAddICAL.vue'),
      name: 'plumguide_add_ical',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/rateplans/paxprice',
      component: () => import('../components/management/ratePlans/paxPrice.vue'),
      name: 'pax_price',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/otas',
      component: () => import('../components/management/OTAS/OtaMappings.vue'),
      name: 'otas',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/misterbb/misterbb',
      component: () => import('../components/management/OTAS/misterb&b/misterB&BAddICAL.vue'),
      name: 'MisterBB_add_ical',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/ynnovfat',
      component: () => import('../components/management/ynnovFat/ynnovFat.vue'),
      name: 'ynnovfat',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/users/users',
      component: () => import('../components/management/users/users.vue'),
      name: 'manage_users',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/tmt',
      component: () => import('../components/management/TMT/tmt.vue'),
      name: 'manage_tmt',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/property',
      component: () => import('../components/management/property/property.vue'),
      name: 'manage_property',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/users/owners',
      component: () => import('../components/management/users/owners.vue'),
      name: 'manage_owners',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/organization',
      component: () => import('../components/management/organization/Organization.vue'),
      name: 'manage_organization',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/accommodations/addtowebsite',
      component: () => import('../components/management/accommodations/AddToWebsite.vue'),
      name: 'add_to_website',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/import/reservations',
      component: () => import('../components/management/import/importReservations.vue'),
      name: 'manage_reservations',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/import/guests',
      component: () => import('../components/management/import/importGuests.vue'),
      name: 'manage_guests',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/users/housekeepers',
      component: () => import('../components/management/users/housekeepers.vue'),
      name: 'manage_housekeepers',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/users/api',
      component: () => import('../components/management/users/api.vue'),
      name: 'manage_api',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/rateplans/linkedrateplan',
      component: () => import('../components/management/ratePlans/linkedRatePlan.vue'),
      name: 'linked_rateplan',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/rateplans/limitrateplan',
      component: () => import('../components/management/ratePlans/limitRateplanPax.vue'),
      name: 'limit_rateplan_pax',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/homeaway/homeawayjson',
      component: () => import('../components/management/OTAS/homeAway/homeAwayAvailabilityFromJSON.vue'),
      name: 'homeaway_availability_from_json',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/expedia/expediajson',
      component: () => import('../components/management/OTAS/expedia/expediaAvailabilityFromJSON.vue'),
      name: 'expedia_availability_from_json',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/rateplans/copyrateplan',
      component: () => import('../components/management/ratePlans/copyRatePlan.vue'),
      name: 'copy_rateplan',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/rateplans/childrateplan',
      component: () => import('../components/management/ratePlans/childRatePlan.vue'),
      name: 'child_rateplan',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/accommodations/changefromproperty',
      component: () => import('../components/management/accommodations/ChangeFromProperty.vue'),
      name: 'change_from_property',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/booking/bookingjson',
      component: () => import('../components/management/OTAS/booking/bookingAvailabilityFromJSON.vue'),
      name: 'booking_availability_from_json',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/airbnb/airbnbjson',
      component: () => import('../components/management/OTAS/airbnb/airbnbAvailabilityFromJSON.vue'),
      name: 'availability_from_json_airbnb',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/airbnb/airbnbical',
      component: () => import('../components/management/OTAS/airbnb/airbnbAddICAL.vue'),
      name: 'airbnb_add_ical',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/accommodations/addextratax',
      component: () => import('../components/management/accommodations/AddExtraTaxFromMR.vue'),
      name: 'add_extra_tax',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // impersonate
    {
      path: '/management/impersonate',
      component: () => import('@/views/Impersonate.vue'),
      name: 'impersonate',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Networking
    {
      path: '/management/networking',
      component: () => import('@/views/ManagementNetworkView.vue'),
      name: 'networking_partners',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Whats New
    {
      path: '/management/whats-new',
      component: () => import('@/views/ManagementWhatsNewView.vue'),
      name: 'whats_new',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Quarantine
    {
      path: '/management/quarantine',
      component: () => import('@/views/quarantine/EmailsView.vue'),
      name: 'emails_quarantine',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/integrationCheck',
      component: () => import('../components/management/CHM/IntegrationCheck.vue'),
      name: 'integration_check',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/sendRatesAvailabilityToCM',
      component: () => import('../components/management/CHM/SendRatePlansOnlyAvailability.vue'),
      name: 'send_rp_availability_to_cm',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/management/setAccommodationsMaxAvailability',
      component: () => import('../components/management/CHM/SetAccommodationsMaxAvailability.vue'),
      name: 'set_accommodations_max_availability',
      meta: {
        auth: true,
        permissions: ['admin'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default managementRoutes;
