const reservationsRoutes = [{
  path: '/rates',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Rates Show
    {
      path: ':accommodationId/',
      component: () => import('../views/rates/RatesEditView.vue'),
      name: 'rates.edit',
      meta: {
        auth: true,
        permissions: ['calendar_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'price-map',
      component: () => import('@/views/rates/PriceMapView.vue'),
      name: 'rates.price_map',
      meta: {
        auth: true,
        permissions: ['calendar_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default reservationsRoutes;
