const welcomerRoutes = [{
  path: '/welcomer',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // #################################
    // ########### Dashboard ###########
    //#################################
    {
      path: '/welcomer/dashboard',
      component: () => import('@/views/DashboardView.vue'),
      name: 'welcomer.dashboard',
      meta: {
        auth: true,
        permissions: ['welcomer_dashboard_view'],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######### Reservations ##########
    // #################################
    // Reservations List
    {
      path: '/welcomer/reservations',
      component: () => import('@/views/reservations/ListView.vue'),
      name: 'welcomer.reservation.index',
      meta: {
        auth: true,
        permissions: ['welcomer_reservation_view'],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Reservations MAP
    {
      path: '/welcomer/reservations/map',
      component: () => import('@/views/reservations/ReservationsMapView.vue'),
      name: 'welcomer.reservation.map',
      meta: {
        auth: true,
        permissions: ['welcomer_reservation_view'],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ############ Tasks ##############
    // #################################
    // Check-in/outs
    {
      path: '/welcomer/tasks/check-ins-outs',
      component: () => import('../views/tasks/CheckInOuts.vue'),
      name: 'welcomer.check_in_outs',
      meta: {
        auth: true,
        permissions: ['welcomer_view_ci_co_page'],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // AIMA
    {
      path: '/welcomer/sef',
      component: () => import('../views/sef/ListView.vue'),
      name: 'welcomer.sef',
      meta: {
        auth: true,
        permissions: ['welcomer_sef_view'],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Concierge
    {
      path: '/welcomer/digital-concierge',
      component: () => import('../views/digitalConcierge/CheckInOnline.vue'),
      name: 'welcomer.digitalConcierge',
      meta: {
        auth: true,
        permissions: ['welcomer_ci_online_view'],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // Maintenances
    {
      path: '/welcomer/maintenance',
      component: () => import('@/views/tasks/Maintenance.vue'),
      name: 'welcomer.maintenance',
      meta: {
        auth: true,
        permissions: ['welcomer_maintenance_view'],
        title: 'List',
        layout: 'welcomer',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ###### Reminders & Notes ########
    // #################################
    {
      path: '/welcomer/reminders',
      component: () => import('../views/remindersView.vue'),
      name: 'welcomer.reminders',
      meta: {
        auth: true,
        permissions: [],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: '/welcomer/notes',
      component: () => import('../views/notesView.vue'),
      name: 'welcomer.notes',
      meta: {
        auth: true,
        permissions: [],
        layout: 'welcomer',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ########## LiveChat #############
    // #################################
    {
      path: '/welcomer/live-chat',
      component: () => import('@/components/schedulers/Welcomer/WelcomerLiveChat.vue'),
      name: 'welcomer.live_chat',
      meta: {
        auth: true,
        permissions: ['welcomer_communications_livechat_view'],
        title: 'Live Chat',
        layout: 'welcomer',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######### Networking ############
    // #################################
    {
      path: '/welcomer/networking',
      component: () => import('../views/networking/NetworkingIndex.vue'),
      name: 'welcomer.networking',
      meta: {
        auth: true,
        permissions: ['welcomer_networking_view'],
        title: 'Networking',
        layout: 'welcomer',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // #################################
    // ######### Marketplace ###########
    // #################################
    {
      path: '/welcomer/marketplace',
      component: () => import('../views/marketplace/MarketplaceIndex.vue'),
      name: 'welcomer.marketplace',
      meta: {
        auth: true,
        permissions: ['welcomer_marketplace_view'],
        title: 'Marketplace',
        layout: 'welcomer',
        // icon: 'home',
        // keepAlive: true
      },
    },
    // #################################
    // ########### Timeline ############
    // #################################
    {
      path: '/welcomer/timeline',
      component: () => import('../views/TimelineView.vue'),
      name: 'welcomer.timeline',
      meta: {
        auth: true,
        permissions: ['welcomer_timeline_view'],
        title: 'Marketplace',
        layout: 'welcomer',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default welcomerRoutes;
