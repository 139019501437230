/* eslint-disable no-param-reassign */
import { nextTick } from 'vue';
import axios from 'axios';
import { createI18n } from 'vue-i18n';
import en from '../locales/en.json';
import pt from '../locales/pt.json';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

export const SUPPORT_LOCALES = ['en', 'pt', 'fr', 'es'];

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  }
  else {
    i18n.global.locale.value = locale;
  }

  axios.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html').setAttribute('lang', locale);
  dayjs.locale(locale);

  dayjs.extend(updateLocale);
  dayjs.updateLocale(locale, {
    weekStart: 1,
  });
}

export function setupI18n(options) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    `../locales/${locale}.json`
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

export const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: 'pt',
  fallbackLocale: 'en',
  messages: {
    pt,
  }
});
