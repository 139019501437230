<script setup>
import { computed, ref } from 'vue';
import useAuthStore from '@/stores/auth';
import pt from 'element-plus/dist/locale/pt.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import fr from 'element-plus/dist/locale/fr.mjs';
import es from 'element-plus/dist/locale/es.mjs';

const authStore = useAuthStore();

const language = ref(en);
const locale = computed(() => {
  switch(authStore.lang){
    case 'pt':
      return language.value = pt;
    case 'es':
      return language.value = es;
    case 'fr':
      return language.value = fr;
    case 'en':
      return language.value = en;
    default:
      return language.value = en;
  }
});
</script>

<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>
