const reservationsRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    {
      path: '/organization',
      component: () => import('../views/organization/OrganizationView.vue'),
      name: 'organization',
      meta: {
        auth: true,
        permissions: ['organization_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    }
  ],
}];

export default reservationsRoutes;
