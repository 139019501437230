const configsRoutes = [{
  path: '/configs',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Configs
    {
      path: '',
      component: () => import('../views/configs/indexView.vue'),
      name: 'configs',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // {
    //   path: '/tasks/sef',
    //   component: () => import('../views/sef/ListView.vue'),
    //   name: 'tasks.index',
    //   meta: {
    //     auth: true,
    //     permissions: ['sef_view'],
    //     // title: 'Dashboard',
    //     // icon: 'home',
    //     // keepAlive: true
    //   },
    // },

  ],
}];

export default configsRoutes;
