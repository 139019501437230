import { useNotifications } from '@/composables/notifications';
import ApiService from '@/services/api_service';
import { EchoService } from '@/services/web_socket';
import { useAppStore } from '@/stores/app';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import useAuthStore from '../stores/auth';
import { TokenService } from './token_service';

class AuthenticationError extends Error {
  errorCode: any;

  constructor(errorCode: any, message: string | undefined) {
    super(message);
    this.name = this.constructor.name;
    if (message != null) {
      this.message = message;
    }
    this.errorCode = errorCode;
  }
}

const AuthService = {
  async signIn(signInData: any) {
    // const authStore = useAuthStore();

    const requestData: AxiosRequestConfig = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': 'pt'
      },
      // url: '/oauth/token',
      url: '/api/fe/oauth/token',
      data: qs.stringify({
        grant_type: 'password',
        client_id: import.meta.env.VITE_APP_CLIENT_ID,
        client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
        username: signInData.username,
        password: signInData.password
      }),
    };

    try {
      const response = await ApiService.customRequest(requestData);

      // TokenService.saveToken(response.data.access_token);
      // TokenService.saveRefreshToken(response.data.refresh_token);

      // ApiService.setHeader();

      // ApiService.mount401Interceptor();

      // authStore.set2FAStatus(response.data.two_fa);

      // EchoService.connect();

      return response.data;
    }
    catch (error: any) {
      this.catchError(error);
    }

    return null;
  },

  async refreshToken() {
    const refreshToken = TokenService.getRefreshToken();

    const requestData: AxiosRequestConfig = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      url: '/oauth/token',
      // data: qs.stringify({
      //   grant_type: 'refresh_token',
      //   refresh_token: refreshToken,
      //   client_id: import.meta.env.VITE_APP_CLIENT_ID,
      //   client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
      // }),
      data: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: import.meta.env.VITE_APP_CLIENT_ID,
        client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      ApiService.setHeader();

      return response.data.access_token;
    }
    catch (error: any) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.error_description,
      );
    }
  },

  async signOut() {
    // const requestData: AxiosRequestConfig = {
    //   method: 'post',
    //   url: '/logout',
    // };

    try {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      ApiService.removeHeader();
      ApiService.unmount401Interceptor();

      EchoService.disconnect();

      const authStore = useAuthStore();
      authStore.removeUserInformation();

      const appStore = useAppStore();
      appStore.handleLogout();
    }
    catch (error: any) {
      // to prevent errors
    }

    try {
      await ApiService.post('/api/fe/logout', {});
    }
    catch (error: any) {
    //   this.catchError(error);
    }

    return true;
  },

  // async signup(email: any, password: any, name: any) {
  async signup() {
    // const signupData: AxiosRequestConfig = {
    //   method: 'post',
    //   headers: { 'Content-Type': 'application/json' },
    //   url: '/oauth/signup',
    //   data: {
    //     email,
    //     password,
    //     name,
    //   },
    // };
    // try {
    //   return await ApiService.customRequest(signupData);
    // } catch (error) {
    //   this.catchError(error);
    // }
  },

  async impersonate(userID: any) {
    const response = await ApiService.put(`/api/fe/management/impersonate/${userID}`, {});

    if (response.status === 200) {
      TokenService.saveImpersonateToken(response.data.access_token);
      ApiService.setHeader();

      return response.data;
    }

    return null;
  },

  async unimpersonate() {

    const {
      errorNotification,
    } = useNotifications();

    await TokenService.removeImpersonateToken();

    await nextTick();

    ApiService.setHeader();

    errorNotification({
      message: 'Voltou ao utilizador de Admin original!'
    });

    try {
      await ApiService.delete('/api/fe/impersonate');
    }
    catch (error) {
      // to prevent fail
    }

    return true;
  },

  catchError(error: any) {
    let status;
    let description;
    if (error.response === undefined) {
      status = error.message;
      description = error.message;
    }
    else if (error.response.status === 400 && error.response.data.error_code === 400) {
      status = 400;
      description = error.response.data.message;
    }
    else {
      status = error.response.status;
      description = error.response.data.error_description;
    }
    throw new AuthenticationError(status, description);
  },

  async getUser(force: boolean = false) {
    const authStore = useAuthStore();

    return await authStore.getUser(force);
  },

  async getPermissions() {
    const authStore = useAuthStore();

    return authStore.getPermissions();
  },

  async validate2FA(data: object, lang: string, authToken: string) {
    // const authStore = useAuthStore();

    const requestData: AxiosRequestConfig = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'Accept-Language': lang
      },
      url: '/api/fe/2fa/validate',
      data
    };

    // try {
    const response = await ApiService.customRequest(requestData);

    return response.data;
    // }
    // catch (error: any) {
    // throw error;
    // this.catchError(error);
    // }
  },

  async resend2FAEmailCode(data: object, lang: string, authToken: string) {
    const requestData: AxiosRequestConfig = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'Accept-Language': lang
      },
      url: '/api/fe/2fa/resendCode',
      data
    };

    const response = await ApiService.customRequest(requestData);

    return response.data;
  },
};

export { AuthService, AuthenticationError };
