const reservationsRoutes = [{
  path: '/reservations',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Reservations Index
    {
      path: '',
      component: () => import('../views/reservations/ListView.vue'),
      name: 'reservations.index',
      meta: {
        auth: true,
        permissions: ['reservation_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Reservations Create
    {
      path: 'new',
      component: () => import('../views/reservations/NewView.vue'),
      name: 'reservations.create',
      meta: {
        auth: true,
        permissions: ['reservation_create_edit'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // Reservations Edit
    {
      path: ':reservationId/edit',
      component: () => import('../views/reservations/EditView.vue'),
      name: 'reservations.edit',
      meta: {
        auth: true,
        permissions: ['reservation_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    // View Reservations MAP
    {
      path: 'map',
      component: () => import('../views/reservations/ReservationsMapView.vue'),
      name: 'reservations.map',
      meta: {
        auth: true,
        permissions: ['reservation_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default reservationsRoutes;
