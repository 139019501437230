import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faAngleDown,
  faAngleUp,
  faAnglesRight,
  faArrowDown,
  faArrowLeftFromLine,
  faArrowLeftToArc,
  faArrowProgress,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faArrowRightFromLine,
  faArrowRightToArc,
  faArrowRightToBracket,
  faArrowRightToLine,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowUp,
  faArrowUpRight,
  faArrowUpRightFromSquare,
  faArrowsRotateReverse,
  faArrowsSpin,
  faArrowsUpDown,
  faAsterisk,
  faBaby,
  faBallotCheck,
  faBan,
  faBars,
  faBed,
  faBedBunk,
  faBellConcierge,
  faBiohazard,
  faBolt,
  faBoltLightning,
  faBoltSlash,
  faBriefcase,
  faBroom,
  faBroomWide,
  faBuilding,
  faBuildingUser,
  faCakeCandles,
  faCalendarDays,
  faCartShopping,
  faChartColumn,
  faChartLine,
  faChartLineDown,
  faChartLineUp,
  faChartNetwork,
  faChartPie,
  faChartPieSimple,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faChildReaching,
  faCircle,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleBolt,
  faCircleCheck,
  faCircleDot,
  faCircleEllipsis,
  faCircleEuro,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCirclePause,
  faCirclePlus,
  faCircleQuestion,
  faCircleStar,
  faCircleUp,
  faCircleUser,
  faCircleXmark,
  faCircleY,
  faClock,
  faClockRotateLeft,
  faClone,
  faCloudArrowUp,
  faCoffee,
  faCogs,
  faCoins,
  faCommentDots,
  faCommentLines,
  faCommentSms,
  faCommentsAlt,
  faCopy,
  faCreditCard,
  faCreditCardBlank,
  faCrown,
  faDown,
  faDownload,
  faEarthEurope,
  faEllipsis,
  faEllipsisStroke,
  faEllipsisStrokeVertical,
  faEllipsisVertical,
  faEnvelope,
  faEquals,
  faEraser,
  faEuroSign,
  faEye,
  faEyeSlash,
  faFaceFrown,
  faFaceSmile,
  faFaceWorried,
  faFamily,
  faFileAlt,
  faFileArrowDown,
  faFileChartColumn,
  faFileCircleCheck,
  faFileCircleMinus,
  faFileCircleXmark,
  faFileContract,
  faFileExcel,
  faFileImport,
  faFileInvoice,
  faFilePdf,
  faFileSpreadsheet,
  faFileUser,
  faFileXls,
  faFileXml,
  faFiles,
  faFilter,
  faFlag,
  faFloppyDisk,
  faFloppyDiskCircleArrowRight,
  faGauge,
  faGear,
  faGearCode,
  faGift,
  faGlobePointer,
  faGraduationCap,
  faGrid,
  faGripDotsVertical,
  faHandsClapping,
  faHandshake,
  faHotel,
  faHourglassStart,
  faHouse,
  faIdCard,
  faImage,
  faImageSlash,
  faImages,
  faInfinity,
  faKey,
  faLifeRing,
  faLightbulb,
  faLink,
  faList,
  faListCheck,
  faListRadio,
  faListTimeline,
  faLocationDot,
  faLock,
  faLockKeyhole,
  faLockKeyholeOpen,
  faLockOpen,
  faMagnifyingGlass,
  faMap,
  faMapMarker,
  faMemoPad,
  faMessageBot,
  faMessageLines,
  faMessages,
  faMobile,
  faMoon,
  faMoonStars,
  faNewspaper,
  faNote,
  faObjectsColumn,
  faPalette,
  faPaperPlane,
  faPaperPlaneTop,
  faPenCircle,
  faPenToSquare,
  faPencil,
  faPeoplePants,
  faPerson,
  faPersonShelter,
  faPhone,
  faPipe,
  faPlug,
  faPlus,
  faPrint,
  faQuestion,
  faReceipt,
  faRectangleHistory,
  faReply,
  faRotate,
  faScrewdriverWrench,
  faShieldCheck,
  faShieldKeyhole,
  faShieldXmark,
  faShuffle,
  faSignIn,
  faSignOut,
  faSliders,
  faSparkles,
  faSpinner,
  faSquare,
  faSquareCheck,
  faSquarePlus,
  faSquarePollVertical,
  faStar,
  faStarSharp,
  faStickyNote,
  faSubtitles,
  faSuitcase,
  faSunBright,
  faSync,
  faTableList,
  faTag,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTrash,
  faTrashCan,
  faTriangle,
  faTriangleExclamation,
  faUniversity,
  faUpload,
  faUser,
  faUserGroup,
  faUserHeadset,
  faUserPen,
  faUserPlus,
  faUserPolice,
  faUserSecret,
  faUserTie,
  faUsers,
  faUsersLine,
  faVectorSquare,
  faWallet,
  faWifiFair,
  faWrench,
  faXmark
} from '@fortawesome/pro-light-svg-icons';

import {
  faHeart,
  faAddressCard as fasAddressCard,
  faAnglesRight as fasAnglesRight,
  faArrowLeftToArc as fasArrowLeftToArc,
  faArrowProgress as fasArrowProgress,
  faArrowRight as fasArrowRight,
  faArrowRightArrowLeft as fasArrowRightArrowLeft,
  faArrowRightFromBracket as fasArrowRightFromBracked,
  faArrowRightToArc as fasArrowRightToArc,
  faArrowRotateLeft as fasArrowRotateLeft,
  faArrowRotateRight as fasArrowRotateRight,
  faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
  faArrowsRotate as fasArrowsRotate,
  faBaby as fasBaby,
  faBars as fasBars,
  faBed as fasBed,
  faBedBunk as fasBedBunk,
  faBellConcierge as fasBellConcierge,
  faBolt as fasBolt,
  faBroomWide as fasBroomWide,
  faBuilding as fasBuilding,
  faBuildingColumns as fasBuildingColumns,
  faBuildingUser as fasBuildingUser,
  faCakeCandles as fasCakeCandles,
  faCalendarDay as fasCalendarDay,
  faCalendarDays as fasCalendarDays,
  faCalendarWeek as fasCalendarWeek,
  faCartShopping as fasCartShopping,
  faChartColumn as fasChartColumn,
  faChartLineDown as fasChartLineDown,
  faChartLineUp as fasChartLineUp,
  faChartNetwork as fasChartNetwork,
  faChartPie as fasChartPie,
  faChartPieSimple as fasChartPieSimple,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faCheckDouble as fasCheckDouble,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChild as fasChild,
  faChildReaching as fasChildReaching,
  faCircleBolt as fasCircleBolt,
  faCircleEllipsis as fasCircleEllipsis,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasCircleInfo,
  faCirclePlus as fasCirclePlus,
  faCircleQuestion as fasCircleQuestion,
  faCircleStar as fasCircleStar,
  faCircleUser as fasCircleUser,
  faCircleXmark as fasCircleXmark,
  faCircleY as fasCircleY,
  faClock as fasClock,
  faClockRotateLeft as fasClockRotateLeft,
  faClone as fasClone,
  faCommentDots as fasCommentDots,
  faCopy as fasCopy,
  faCreditCard as fasCreditCard,
  faCrown as fasCrown,
  faEllipsis as fasEllipsis,
  faEnvelope as fasEnvelope,
  faEye as fasEye,
  faFamily as fasFamily,
  faFileAlt as fasFileAlt,
  faFileArrowDown as fasFileArrowDown,
  faFileCircleCheck as fasFileCircleCheck,
  faFileCircleMinus as fasFileCircleMinus,
  faFileCircleXmark as fasFileCircleXmark,
  faFileContract as fasFileContract,
  faFileExcel as fasFileExcel,
  faFileInvoice as fasFileInvoice,
  faFilePdf as fasFilePdf,
  faFileSpreadsheet as fasFileSpreadsheet,
  faFileUser as fasFileUser,
  faFileXls as fasFileXls,
  faFiles as fasFiles,
  faFlag as fasFlag,
  faGauge as fasGauge,
  faGaugeHigh as fasGaugeHigh,
  faGear as fasGear,
  faGearCode as fasGearCode,
  faGears as fasGears,
  faGraduationCap as fasGraduationCap,
  faGrid as fasGrid,
  faHandshake as fasHandshake,
  faHotel as fasHotel,
  faHourglassStart as fasHourglassStart,
  faHouse as fasHouse,
  faIdCard as fasIdCard,
  faLifeRing as fasLifeRing,
  faLightbulb as fasLightBulb,
  faList as fasList,
  faListCheck as fasListCheck,
  faListRadio as fasListRadio,
  faListTimeline as fasListTimeline,
  faLocationDot as fasLocationDot,
  faLock as fasLock,
  faLockKeyhole as fasLockKeyhole,
  faLockKeyholeOpen as fasLockKeyholeOpen,
  faLockOpen as fasLockOpen,
  faMagnifyingGlass as fasMagnifyingGlass,
  faMessageBot as fasMessageBot,
  faMessageLines as fasMessageLines,
  faMessages as fasMessages,
  faMobile as fasMobile,
  faMoon as fasMoon,
  faMoonStars as fasMoonStars,
  faNote as fasNote,
  faObjectsColumn as fasObjectsColumn,
  faPalette as fasPalette,
  faPaperPlane as fasPaperPlane,
  faPaperPlaneTop as fasPaperPlaneTop,
  faPenCircle as fasPenCircle,
  faPenToSquare as fasPenToSquare,
  faPeoplePants as fasPeoplePants,
  faPerson as fasPerson,
  faPersonShelter as fasPersonShelter,
  faPlus as fasPlus,
  faQuestion as fasQuestion,
  faReply as fasReply,
  faScrewdriverWrench as fasScrewdriverWrench,
  faShieldCheck as fasShieldCheck,
  faShieldXmark as fasShieldXmark,
  faShuffle as fasShuffle,
  faSliders as fasSliders,
  faSparkles as fasSparkles,
  faSquareCheck as fasSquareCheck,
  faSquarePen as fasSquarePen,
  faSquarePlus as fasSquarePlus,
  faStar as fasStar,
  faSubtitles as fasSubtitles,
  faSuitcase as fasSuitcase,
  faSunBright as fasSunBright,
  faTableList as fasTableList,
  faTag as fasTag,
  faThumbtack as fasThumbtack,
  faTicket as fasTicket,
  faTrashCan as fasTrashCan,
  faTriangleExclamation as fasTriangleExclamation,
  faUser as fasUser,
  faUserGroup as fasUserGroup,
  faUserHeadset as fasUserHeadset,
  faUserPen as fasUserPen,
  faUserPlus as fasUserPlus,
  faUserTie as fasUserTie,
  faUsers as fasUsers,
  faUsersLine as fasUsersLine,
  faVectorSquare as fasVectorSquare,
  faWifi as fasWifi,
  faWifiSlash as fasWifiSlash,
  faWrench as fasWrench,
  faXmark as fasXmark
} from '@fortawesome/pro-solid-svg-icons';

import {
  faArrowLeftToArc as farArrowLeftToArc,
  faArrowRightToArc as farArrowRightToArc,
  faCheckCircle as farCheckCircle,
  faCircleMinus as farCircleMinus,
  faCircleQuestion as farCircleQuestion,
  faCircleStar as farCircleStar,
  faCircleXmark as farCircleXmark,
  faFileInvoice as farFileInvoice,
  faFilePdf as farFilePdf,
  faFileXls as farFileXls,
  faHourglassStart as farHourglassStart,
  faNote as farNote,
  faUsers as farUsers
} from '@fortawesome/pro-regular-svg-icons';

import {
  faWhatsapp,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import {
  faLightArrowRightToArcCircleBolt,
  faLightBarsCircleArrowLeft,
  faLightCircleCheckCircleArrowRight,
  faLightCreditCardCircleQuestion,
  faLightFileInvoiceCircleCheck,
  faLightFileInvoiceCircleXmark,
  faLightFlagSlash,
  faLightPaperPlaneCircleBolt,
  faSolidCreditCardCircleXmark,
  faSolidFileInvoiceCircleCheck,
  faSolidFileInvoiceCircleXmark,
  faSolidFlagCircleCheck,
  faSolidFlagCircleXmark,
  faSolidFlagClock,
  faSolidFlagUser,
  faSolidGlobePointerSlash
} from '@awesome.me/kit-4f31246daa/icons/kit/custom';

export function useFontAwesome() {
  const addIcons = () => {
    library.add(
      faCoffee,
      faChevronDown,
      faChevronUp,
      faTableList,
      faPaperPlane,
      faListTimeline,
      faLightbulb,
      faCircleQuestion,
      faGauge,
      faBed,
      faCalendarDays,
      faSuitcase,
      faListCheck,
      faReceipt,
      faWallet,
      faChartPie,
      faFilePdf,
      faFileExcel,
      faFilter,
      faPlus,
      faFlag,
      faEllipsisVertical,
      faEraser,
      faMagnifyingGlass,
      faSliders,
      faSpinner,
      faChevronLeft,
      faChevronRight,
      faMobile,
      faEnvelope,
      faFloppyDisk,
      faFloppyDiskCircleArrowRight,
      faPenToSquare,
      faBroom,
      faArrowRightToBracket,
      faHeart,
      faArrowsUpDown,
      faHouse,
      faBuilding,
      faBedBunk,
      faImages,
      faImage,
      faTrashCan,
      faTag,
      faMoon,
      faIdCard,
      faCloudArrowUp,
      faUser,
      faCircleInfo,
      faCopy,
      faPencil,
      faCommentSms,
      faNewspaper,
      faCircle,
      faCircleEuro,
      faEye,
      faSquareCheck,
      faSquarePlus,
      faBan,
      faArrowRightToLine,
      faFileInvoice,
      faTimes,
      faClone,
      faTriangleExclamation,
      faTriangle,
      faPhone,
      faLocationDot,
      faList,
      faAddressCard,
      faBriefcase,
      faUsersLine,
      faBroomWide,
      faObjectsColumn,
      faArrowRightToArc,
      faArrowLeftToArc,
      faScrewdriverWrench,
      faChartColumn,
      faLifeRing,
      faArrowRightFromBracket,
      faStarSharp,
      faCommentLines,
      faFaceSmile,
      faFaceFrown,
      faAngleUp,
      faAngleDown,
      faXmark,
      faChildReaching,
      faChild,
      faBaby,
      faPerson,
      faPersonShelter,
      faEllipsisStrokeVertical,
      faCheck,
      faShieldCheck,
      faFileAlt,
      faMapMarker,
      faClock,
      faNote,
      faArrowProgress,
      faUserHeadset,
      faYoutube,
      faGear,
      faCreditCard,
      faRectangleHistory,
      faBellConcierge,
      faEarthEurope,
      faSync,
      faRotate,
      faCoins,
      faUsers,
      faUniversity,
      faCogs,
      faBars,
      faCommentsAlt,
      faPlug,
      faGrid,
      faHotel,
      faUserSecret,
      faUserPolice,
      faFileContract,
      faSunBright,
      faCircleCheck,
      faBolt,
      faMessageLines,
      faPrint,
      faMap,
      faTrash,
      faCircleUser,
      fasCircleUser,
      faCirclePlus,
      faLock,
      faAsterisk,
      faFaceWorried,
      faThumbtack,
      faSignIn,
      faSignOut,
      faFamily,
      faCakeCandles,
      faCircleXmark,
      faEuroSign,
      faArrowDown,
      faBoltLightning,
      faKey,
      faBuildingUser,
      faUserTie,
      faFileChartColumn,
      fasMoon,
      fasPerson,
      faArrowRight,
      faImageSlash,
      faFileXml,
      faUpload,
      faWifiFair,
      faDown,
      faSquarePollVertical,
      faCircleMinus,
      faArrowRotateLeft,
      faCheckDouble,
      fasSquarePlus,
      fasMessageLines,
      faArrowUpRightFromSquare,
      faBoltSlash,
      faListRadio,
      faFileCircleMinus,
      faFileImport,
      faHourglassStart,
      farHourglassStart,
      faCircleBolt,
      faCircleY,
      fasCircleY,
      faArrowRotateRight,
      fasCirclePlus,
      fasSuitcase,
      fasPaperPlane,
      fasListTimeline,
      fasLightBulb,
      fasCircleQuestion,
      fasSunBright,
      faPenCircle,
      fasFlag,
      faInfinity,
      faArrowUp,
      faArrowDown,
      faEquals,
      faArrowsSpin,
      faEllipsisStroke,
      faLockOpen,
      faPipe,
      fasCircleBolt,
      fasPenCircle,
      fasArrowLeftToArc,
      fasArrowRightToArc,
      fasBellConcierge,
      fasBroomWide,
      fasScrewdriverWrench,
      fasIdCard,
      fasFileInvoice,
      fasCreditCard,
      fasCheckCircle,
      fasCheckDouble,
      fasPenToSquare,
      fasTrashCan,
      fasLock,
      fasArrowRotateLeft,
      fasFileCircleMinus,
      fasGears,
      fasGrid,
      fasHotel,
      fasFileContract,
      fasArrowRotateRight,
      fasArrowRightFromBracked,
      fasArrowProgress,
      fasLifeRing,
      fasUserHeadset,
      faSparkles,
      fasSparkles,
      fasUser,
      faSquare,
      fasSquareCheck,
      faStar,
      fasStar,
      faCrown,
      fasCrown,
      fasCircleXmark,
      faCircleEllipsis,
      fasCircleEllipsis,
      faUserGroup,
      faPeoplePants,
      fasCircleStar,
      faCircleStar,
      fasPeoplePants,
      fasFamily,
      fasUserGroup,
      faCirclePause,
      fasCheck,
      fasFileInvoice,
      faEye,
      fasEye,
      faArrowUpRight,
      fasGearCode,
      faGearCode,
      fasObjectsColumn,
      fasListCheck,
      fasAddressCard,
      fasCalendarDays,
      fasBed,
      fasBuildingUser,
      fasChartColumn,
      fasChartPie,
      faLightBarsCircleArrowLeft,
      faLightPaperPlaneCircleBolt,
      faSolidFlagCircleXmark,
      faSolidFlagCircleCheck,
      faSolidFlagClock,
      faSolidFlagUser,
      farCircleStar,
      faCartShopping,
      faChartLineUp,
      faChartLineDown,
      faThumbsDown,
      faThumbsUp,
      faEllipsis,
      fasEllipsis,
      faAnglesRight,
      fasAnglesRight,
      faArrowRightFromLine,
      faCircleDot,
      faArrowLeftFromLine,
      fasListRadio,
      fasMobile,
      fasEye,
      faFileCircleXmark,
      fasFileCircleXmark,
      fasFileCircleCheck,
      faFileCircleCheck,
      faCircleExclamation,
      fasGear,
      fasCartShopping,
      faArrowRightToArc,
      fasArrowRightToArc,
      faCircleArrowUp,
      fasPersonShelter,
      fasFlag,
      fasChildReaching,
      fasBaby,
      faMoonStars,
      fasMoonStars,
      faChartNetwork,
      fasChartNetwork,
      fasArrowUpRightFromSquare,
      faWhatsapp,
      fasCircleExclamation,
      faSolidCreditCardCircleXmark,
      faHandsClapping,
      fasMessages,
      faMessages,
      fasReply,
      faReply,
      faChartPieSimple,
      fasChartPieSimple,
      faLightCircleCheckCircleArrowRight,
      fasChartLineUp,
      fasChartLineDown,
      fasTag,
      fasHandshake,
      faHandshake,
      faStickyNote,
      fasArrowRight,
      faUserPlus,
      faPaperPlaneTop,
      fasMagnifyingGlass,
      fasUserPlus,
      fasPaperPlaneTop,
      faXmark,
      faPalette,
      fasThumbtack,
      fasPalette,
      fasBuilding,
      fasBedBunk,
      fasHouse,
      faGripDotsVertical,
      faLightCreditCardCircleQuestion,
      fasCopy,
      fasClock,
      fasNote,
      faFiles,
      fasFiles,
      fasGaugeHigh,
      fasChild,
      fasUserTie,
      fasBuildingColumns,
      fasShieldCheck,
      faCreditCardBlank,
      faArrowsRotateReverse,
      fasWifi,
      fasWifiSlash,
      faMemoPad,
      fasChevronLeft,
      faBallotCheck,
      faShieldXmark,
      fasShieldXmark,
      fasTicket,
      fasCircleInfo,
      faBiohazard,
      faGlobePointer,
      fasBolt,
      fasEnvelope,
      fasSliders,
      fasUsers,
      fasBars,
      fasChevronRight,
      faUserPen,
      fasUserPen,
      faQuestion,
      fasQuestion,
      faLightArrowRightToArcCircleBolt,
      faVectorSquare,
      fasVectorSquare,
      fasLockOpen,
      farNote,
      faGraduationCap,
      fasGraduationCap,
      fasFilePdf,
      fasFileExcel,
      fasFileXls,
      faFileXls,
      farFilePdf,
      farFileXls,
      faLockKeyhole,
      faLockKeyholeOpen,
      fasLockKeyhole,
      fasLockKeyholeOpen,
      faShuffle,
      faCircleArrowRight,
      fasHourglassStart,
      fasSquarePen,
      farUsers,
      farArrowLeftToArc,
      farArrowRightToArc,
      farCheckCircle,
      farCircleXmark,
      farCircleMinus,
      farCircleQuestion,
      fasLocationDot,
      fasUsersLine,
      fasFileAlt,
      fasCakeCandles,
      fasList,
      faArrowRightArrowLeft,
      fasArrowRightArrowLeft,
      fasCommentDots,
      faCommentDots,
      faEyeSlash,
      faDownload,
      faFileArrowDown,
      fasFileArrowDown,
      faCircleUp,
      farFileInvoice,
      fasClone,
      fasClockRotateLeft,
      faClockRotateLeft,
      faLightFlagSlash,
	    fasShuffle,
      faLightFileInvoiceCircleCheck,
      faLightFileInvoiceCircleXmark,
      faSolidFileInvoiceCircleCheck,
      faSolidFileInvoiceCircleXmark,
      faChartLine,
      faShieldKeyhole,
      fasGauge,
      fasCalendarDay,
      fasCalendarWeek,
      fasCalendarDays,
      faSubtitles,
      fasSubtitles,
      faSolidGlobePointerSlash,
      faLink,
      fasWrench,
      faWrench,
      fasTableList,
      faFileUser,
      fasFileUser,
      faFileSpreadsheet,
      fasFileSpreadsheet,
      faMessageBot,
      fasMessageBot,
      fasCircleInfo,
      fasPlus,
      fasXmark,
      fasArrowsRotate,
      fasTriangleExclamation,
      faGift
    );
  };

  return {
    addIcons,
  };
}
