const checkInOnlineRoutes = [{
  path: '/',
  component: () => import('../layouts/EmptyLayout.vue'),
  children: [
    {
      path: '/ci/:checkOnline/:checkToken',
      name: 'checkInOnline',
      component: () => import('@/views/checkInOnline.vue'),
      meta: {
        auth: false,
        permissions: [],
        layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    {
      path: '/check-in/:checkOnline/:checkToken',
      name: 'checkInOnlineOld',
      component: () => import('@/views/checkInOnline.vue'),
      meta: {
        auth: false,
        permissions: [],
        layout: 'all',
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default checkInOnlineRoutes;
