const baseRoutes = [{
  path: '/statistics',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    {
      path: 'indicators',
      component: () => import('../components/statistics/Indicators.vue'),
      name: 'indicators',
      meta: {
        auth: true,
        permissions: ['stats_indicators_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'reservations',
      component: () => import('../components/statistics/Reservations.vue'),
      name: 'reservation',
      meta: {
        auth: true,
        permissions: ['stats_reservations_view'],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'tasks',
      component: () => import('../views/statistics/TasksView.vue'),
      name: 'task',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'institutional',
      component: () => import('../views/statistics/InstitutionalView.vue'),
      name: 'institutional',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    }
  ],
}];

export default baseRoutes;

