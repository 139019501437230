// import { ref, Ref, computed } from 'vue';
import { ElMessageBox, ElNotification } from 'element-plus';
import { i18n } from '../plugins/i18n';

declare type MessageType = '' | 'success' | 'warning' | 'info' | 'error';
declare type ApplyFunction = () => void;
declare type ConfirmNotificationType = {
  title?: string,
  message: string,
  confirmButtonText?: string,
  cancelButtonText?: string,
  type?: MessageType,
  confirmFunction: ApplyFunction,
  cancelFunction?: ApplyFunction,
};
declare type NotificationType = {
  title?: string,
  message: string,
  type?: MessageType,
  duration?: number,
  onClose?: () => void,
};
declare type ErrorNotificationType = {
  title?: string,
  message?: string,
  type?: MessageType,
};

export function useNotifications() {
  const confirmNotification = async (config: ConfirmNotificationType) => {
    const title = config.title ?? undefined;
    const type = config.type ?? undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const confirmButtonText = config.confirmButtonText ?? i18n.global.t('general.ok');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cancelButtonText = config.cancelButtonText ?? i18n.global.t('general.cancel');

    await ElMessageBox.confirm(
      config.message,
      title,
      {
        confirmButtonText,
        cancelButtonText,
        type,
      },
    )
      .then(async () => {
        await config.confirmFunction();
      })
      .catch(() => {
        if (config.cancelFunction) {
          return config.cancelFunction();
        }
      });
  };

  const notification = (config: NotificationType) => {
    const title = config.title ?? i18n.global.t('general.success');
    const type = config.type ?? 'success';
    const duration = config.duration ?? 4500;

    ElNotification({
      title,
      message: config.message,
      type,
      duration,
      onClose: config.onClose ?? (() => {}),
    });
  };

  const errorNotification = (config: ErrorNotificationType) => {
    const title = config.title ?? undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = config.message ?? i18n.global.t('general.error_generic_message');
    const type = config.type ?? 'error';

    ElMessageBox.alert(
      message,
      title,
      {
        confirmButtonText: 'OK',
        type,
        dangerouslyUseHTMLString: true,
      },
    );
  };

  return {
    confirmNotification,
    notification,
    errorNotification,
  };
}
