const baseRoutes = [{
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    {
      path: 'reminders',
      component: () => import('../views/remindersView.vue'),
      name: 'reminders',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
    {
      path: 'notes',
      component: () => import('../views/notesView.vue'),
      name: 'notes',
      meta: {
        auth: true,
        permissions: [],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },
  ],
}];

export default baseRoutes;
