const invoicingRoutes = [{
  path: '/invoicing',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    // Invoicing
    {
      path: '/invoicing/issued',
      component: () => import('../views/invoicing/IssuedView.vue'),
      name: 'invoicing.issued',
      meta: {
        auth: true,
        permissions: [/*'invoicing_view'*/],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

    {
      path: '/invoicing/to-issue',
      component: () => import('../views/invoicing/ToIssueView.vue'),
      name: 'invoicing.to_issue',
      meta: {
        auth: true,
        permissions: [/*'invoicing_view'*/],
        // title: 'Dashboard',
        // icon: 'home',
        // keepAlive: true
      },
    },

  ],
}];

export default invoicingRoutes;
