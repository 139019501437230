import { useCookies } from '@vueuse/integrations/useCookies';
import dayjs from 'dayjs';

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const IMPERSONATION_TOKEN_KEY = 'impersonation_access_token';
const IS_IMPERSONATING = 'is_impersonating';

const { get, getAll, set, remove, addChangeListener, removeChangeListener } = useCookies(['ynnov'], { doNotParse: false, autoUpdateDependencies: false });

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken: string) {
    localStorage.setItem(TOKEN_KEY, accessToken);

    // const date = dayjs().add(6, 'months').toDate();

    // set(
    //   TOKEN_KEY,
    //   accessToken,
    //   {
    //     // path?: string;
    //     expires: date,
    //     // maxAge?: number;
    //     // domain: 'app.ynnov.pt',
    //     // secure: true,
    //     httpOnly: true,
    //     // sameSite: 'strict' // boolean | 'none' | 'lax' | 'strict';
    //   }
    // );
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(IMPERSONATION_TOKEN_KEY);
    localStorage.removeItem(IS_IMPERSONATING);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    // const date = dayjs().add(6, 'months').toDate();

    // set(
    //   REFRESH_TOKEN_KEY,
    //   refreshToken,
    //   {
    //     // path?: string;
    //     expires: date,
    //     // maxAge?: number;
    //     // domain: 'app.ynnov.pt',
    //     // secure: true,
    //     // httpOnly: true,
    //     // sameSite: 'strict' // boolean | 'none' | 'lax' | 'strict';
    //   }
    // );
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  // Impersonation

  getImpersonateToken() {
    return localStorage.getItem(IMPERSONATION_TOKEN_KEY);
  },

  saveImpersonateToken(accessToken: string) {
    localStorage.setItem(IMPERSONATION_TOKEN_KEY, accessToken);
    localStorage.setItem(IS_IMPERSONATING, '1');
  },

  removeImpersonateToken() {
    return new Promise((resolve) => {
      localStorage.removeItem(IMPERSONATION_TOKEN_KEY);
      localStorage.removeItem(IS_IMPERSONATING);

      resolve(true);
    });
  },

  isImpersonating() {
    return localStorage.getItem(IS_IMPERSONATING) === '1';
  }
};

export { TokenService };
