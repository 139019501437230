import { defineStore } from 'pinia';
import { PropertyService } from '@/services/property_service';
import ApiService from '@/services/api_service';
import { AuthService, AuthenticationError } from '../services/auth_service';
import { TokenService } from '../services/token_service';
import { i18n, loadLocaleMessages, setI18nLanguage } from '@/plugins/i18n';
import router from '@/router';

export default defineStore('auth', {
  state: () => ({
    authenticating: false,
    accessToken: TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: '',
    refreshTokenPromise: null,
    permissions: [],
    lang: 'pt',
    user: null,
    role: null,
    preferences: [],
    inPayment: null,
    isImpersonating: false,
    impersonatingData: null,
    twoFA: null,
    twoFAPageKey: null,
    isDark: 0,
    whatsNewBadge: 0
  }),
  getters: {
    isAuthenticated: state => state.accessToken != null,
  },
  actions: {
    async signIn(signInData: any) {
      this.authenticating = true;
      this.authenticationError = '';
      this.authenticationErrorCode = 0;

      return new Promise((resolve, reject) => {
        AuthService.signIn(signInData).then(async (res) => {
          // this.accessToken = res;
          this.authenticating = false;

          // await AuthService.getUser();
          // await PropertyService.getProperties();

          resolve(res);
        }).catch((err) => {
          if (err instanceof AuthenticationError) {
            this.authenticating = false;
            this.authenticationErrorCode = err.errorCode;
            this.authenticationError = err.message;

            reject(err.message);
          }
        });
      });
    },

    signOut() {
      this.authenticating = false;

      return new Promise((resolve) => {
        AuthService.signOut()
          .then(() => {
            resolve(true);
          })
          .catch(async (err: any) => {
          });
      });
    },

    refreshToken() {
      if (!this.refreshTokenPromise) {
        const p: any = AuthService.refreshToken();
        this.refreshTokenPromise = p;

        p.then(
          (response: any) => {
            this.refreshTokenPromise = null;
            // context.commit('loginSuccess', response);
          },
          (error: any) => {
            this.refreshTokenPromise = error;
          },
        );
      }

      return this.refreshTokenPromise;
    },

    async getUser(force: boolean = false) {

      if (this.user && !force) {
        return this.user;
      }

      const response = await ApiService.get('/api/fe/user');

      this.permissions = response.data.user.permissions;

      this.user = response.data.user;

      this.role = response.data.user.role;

      this.lang = response.data.user.lang ?? 'pt';

      this.preferences = response.data.user.preferences;

      this.inPayment = response.data.user?.in_payment;
      // if (!this.inPayment) {
      //   this.inPayment = { active: true };
      // }
      // this.inPayment.infoMsg = true;

      this.twoFA = response.data.user?.twoFA;

      this.isDark = response.data.user?.is_dark;

      this.whatsNewBadge = response.data.user?.whats_knew;

      await this.changeLang();

      return response.data.user;
    },

    async getPermissions() {
      if (!this.user) {
        await this.getUser();
      }

      return this.permissions;
    },

    async changeLang() {
      await loadLocaleMessages(i18n, this.lang);
      setI18nLanguage(i18n, this.lang);
    },

    async getPreference(preference: string) {
      if (!this.user) {
        await this.getUser();
      }

      return this.preferences[preference];
    },

    setPreferences(preferences: array) {
      if(preferences) {
        this.preferences = preferences;
      }
    },

    getPreferences() {
      return this.preferences;
    },

    removeUserInformation() {
      this.permissions = [];
      this.user = null;
      this.role = null;
      this.lang = 'pt';
    },

    hasPermission(permission: string) {
      if(this.permissions[permission] === undefined) {
        return false;
      }
      return this.permissions[permission] === true;
    },

    async impersonate(userID: any) {
      this.isImpersonating = false;
      this.impersonatingData = null;

      return new Promise((resolve, reject) => {
        AuthService.impersonate(userID).then(async (res) => {
          this.isImpersonating = true;
          this.impersonatingData = res;

          resolve(res);
        }).catch((err) => {
          if (err instanceof AuthenticationError) {
            this.isImpersonating = false;
            this.impersonatingData = null;

            reject(err.message);
          }
        });
      });
    },

    async unimpersonate() {
      return new Promise((resolve, reject) => {
        AuthService.unimpersonate().then(async () => {
          resolve(true);
        }).catch((err) => {
          if (err instanceof AuthenticationError) {
            this.isImpersonating = false;
            this.impersonatingData = null;

            reject(err.message);
          }
        });
      });
    },

    set2FAStatus(status: any) {
      this.twoFA = status;
    },

    setWhatsNewPostsAsRead() {
      this.whatsNewBadge = 0;
    },

    get2FAStatus() {
      return this.twoFA;
    },

    setTwoFAPageKey(pageKey: string) {
      this.twoFAPageKey = pageKey;
    }

    // setToken(token: any) {
    //   sessionStorage.setItem('access_token', token);
    //   this.token = token;
    // },
  },
});
